<!-- components/RunnerGames.vue -->
<!-- UPDATE TEMBOK 22-04-2024 -->
<template>
  <div class="runnergames-top">
    <div class="runnergames-top-left">TIME : {{ counterTimerText }}</div>
    <div class="runnergames-top-mid">
      <img src="../assets/images/runner/logo.png" />
    </div>
  </div>
  <div class="runnergames-frame">
    <canvas id="runnercanvas"></canvas>
  </div>
  <div v-if="gameover" class="runnergames-text">
    GAME OVER !<br />
    <!--span v-if="counterTimer <= 0"> Waktu habis, skor tidak disimpan </span-->
    <span v-if="counterTimer > 0">
      Selamat! Waktu anda : {{ this.formatWaktu(counterTimer) }}
    </span>
    <div @click="newGame" class="runnergames-button">
      <img src="../assets/images/button_next.png" />
    </div>
  </div>
  <div class="runnergames-leaderboard" @click="showLeaderboard">
    <img src="../assets/images/speed/icon_leaderboard.png" />
  </div>
  <div class="runnergames-control">
    <div class="control-up" @click="movePlayerCode(38)">
      <img src="../assets/images/runner/control.png" alt="&uarr;" />
    </div>
    <div class="control-left" @click="movePlayerCode(37)">
      <img src="../assets/images/runner/control.png" alt="&larr;" />
    </div>
    <div class="control-right" @click="movePlayerCode(39)">
      <img src="../assets/images/runner/control.png" alt="&rarr;" />
    </div>
    <div class="control-down" @click="movePlayerCode(40)">
      <img src="../assets/images/runner/control.png" alt="&darr;" />
    </div>
  </div>
  <Transition>
    <div v-if="isLeaderboard" @click.self="hideLeaderboard" class="full-screen modal-v modal-leaderboard">
      <div class="logo-top">
        <img src="../assets/images/runner/logo.png" />
      </div>
      <div class="close-modal" @click="hideLeaderboard">
        <img src="../assets/images/close_icon.png" />
      </div>
      <div class="leaderboard-frame">
        <div class="leaderboard-header">LEADERBOARD</div>
        <div class="leaderboard-container">
          <div v-for="(leaderboard, index) in leaderboardData" :key="index" :class="leaderboard.class">
            {{ leaderboard.nama }}
            <span class="leaderboard-score">
              {{ this.formatWaktu(leaderboard.score) }}</span>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  data() {
    return {
      canvas: null,
      context: null,
      maze: [],
      mazeRow: 25,
      mazeKolom: 51,
      player: { x: 1, y: 1 },
      playerWidth: 50,
      wallWidth: 5,
      marginPlayer: 10,
      imgPlayer: new Image(),
      imgFinish: new Image(),
      imgTekstur: new Image(),
      bgmSound: new Audio(),
      gameover: false,
      counterTimerAwal: 0,
      counterTimer: 0,
      counterInterval: null,
      counterTimerText: "00:00",
      scoreAwal: 60000, // 12.000 + tingkat kesulitan
      totalScore: 0,
      shortestPath: [],
      isLeaderboard: false,
      leaderboardData: [
        { class: "leaderboard-me", nama: "skor saya : ", score: "-" },
      ],
    };
  },
  methods: {
    countDownTimer() {
      this.counterTimerText = this.formatWaktu(this.counterTimer);
      this.counterTimer++;
    },
    generateMaze() {
      const rows = this.mazeRow; // Jumlah Row *HARUS GANJIL
      const columns = this.mazeKolom; // Jumlah Kolom *HARUS GANJIL

      // Initialize an empty maze
      this.maze = Array.from({ length: rows }, () => Array(columns).fill(1));

      // Start DFS dari kiri atas dan kiri bawah -> kanan bawah
      this.depthFirstSearch(1, 1);
      this.depthFirstSearch(1, rows - 2);

      // memastikan maze awal dan finish selalu kosong
      this.maze[1][1] = 0;

      this.maze[1][columns - 2] = 0;
      this.maze[rows - 2][columns - 2] = 0;
      this.maze[rows - 1][columns - 2] = 0;
    },

    depthFirstSearch(x, y) {
      const directions = [
        [0, -2], // Up
        [0, 2], // Down
        [-2, 0], // Left
        [2, 0], // Right
      ];

      this.maze[y][x] = 0; // Mark the current cell as open

      // Randomize the order of directions
      directions.sort(() => Math.random() - 0.5);

      for (const [dx, dy] of directions) {
        const nx = x + dx;
        const ny = y + dy;

        if (
          nx > 0 &&
          nx < this.maze[0].length - 1 &&
          ny > 0 &&
          ny < this.maze.length - 1 &&
          this.maze[ny][nx] === 1
        ) {
          // Mark the wall between the current cell and the neighbor as open
          this.maze[y + dy / 2][x + dx / 2] = 0;
          this.depthFirstSearch(nx, ny);
        }
      }
    },
    findShortestPath() {
      const start = { x: 1, y: 1 };
      const end = { x: this.maze[0].length - 2, y: this.maze.length - 1 };
      const visited = Array.from({ length: this.maze.length }, () =>
        Array(this.maze[0].length).fill(false)
      );

      const path = [];
      this.dfsForShortestPath(start.x, start.y, end, visited, path);

      console.log("Langkah tercepat : ", this.shortestPath.length);
    },

    dfsForShortestPath(x, y, end, visited, path) {
      if (x === end.x && y === end.y) {
        // If reached the end, compare paths
        if (
          path.length < this.shortestPath.length ||
          this.shortestPath.length === 0
        ) {
          this.shortestPath = [...path];
        }
        return;
      }

      visited[y][x] = true;

      const directions = [
        [0, -1], // Up
        [1, 0], // Right
        [0, 1], // Down
        [-1, 0], // Left
      ];

      for (const [dx, dy] of directions) {
        const nx = x + dx;
        const ny = y + dy;

        if (
          nx >= 0 &&
          nx < this.maze[0].length &&
          ny >= 0 &&
          ny < this.maze.length &&
          this.maze[ny][nx] === 0 &&
          !visited[ny][nx]
        ) {
          path.push({ x: nx, y: ny });
          this.dfsForShortestPath(nx, ny, end, visited, path);
          path.pop(); // Backtrack
        }
      }

      visited[y][x] = false;
    },
    drawMaze() {
      const cellSize = this.playerWidth;
      const wallWidth = this.wallWidth; // Set the width of the walls
      const wallLength = cellSize;
      const row = this.maze.length;
      const column = this.maze[0].length;
      var xWall, yWall;

      this.canvas.width =
        Math.floor(column / 2) * (cellSize + wallWidth) + 2 * wallWidth;
      this.canvas.height =
        Math.floor(row / 2) * (cellSize + wallWidth) + 2 * wallWidth + cellSize;

      this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      this.context.fillStyle = "#fff";


      for (let y = 0; y < this.maze.length; y++) {
        for (let x = 0; x < this.maze[y].length; x++) {
          if (this.maze[y][x] === 1) {
            if (x % 2 == 1) {
              // BUAT GARIS HORIZONTAL
              xWall = Math.floor(x / 2) * (cellSize + wallWidth) + wallWidth;
              yWall = Math.floor(y / 2) * (cellSize + wallWidth);
              /*
              this.context.fillRect(xWall, yWall, wallLength, wallWidth);
              this.context.fillStyle = "rgba(220, 220, 220, 0.6)";
              this.context.fillRect(
                xWall,
                yWall + wallWidth,
                wallLength,
                wallWidth * 1.5
              );
              this.context.fillStyle = "#fff";
              */
              this.context.drawImage(
                this.imgTekstur,
                xWall,
                yWall + wallWidth,
                wallLength,
                wallWidth * 1.5
              );
            } else {
              xWall = Math.floor(x / 2) * (cellSize + wallWidth);
              if (y % 2 == 0) {
                //BUAT GARIS KOTAK
                yWall = Math.floor(y / 2) * (cellSize + wallWidth);
                /*
                this.context.fillRect(xWall, yWall, wallWidth, wallWidth);
                this.context.fillStyle = "rgba(220, 220, 220, 0.6)";
                this.context.fillRect(
                  xWall,
                  yWall + wallWidth,
                  wallWidth,
                  wallWidth * 1.5
                );
                this.context.fillStyle = "#fff";
                */
                this.context.drawImage(
                  this.imgTekstur,
                  xWall,
                  yWall + wallWidth,
                  wallWidth,
                  wallWidth * 1.5
                );
              } else {
                // BUAT GARIS VERTICAL
                yWall = Math.floor(y / 2) * (cellSize + wallWidth) + wallWidth;
                /*
                this.context.fillRect(xWall, yWall, wallWidth, wallLength);
                this.context.fillStyle = "rgba(220, 220, 220, 0.6)";
                this.context.fillRect(
                  xWall,
                  yWall + wallWidth,
                  wallWidth,
                  wallLength
                );
                this.context.fillStyle = "#fff";
                */
                this.context.drawImage(
                  this.imgTekstur,
                  xWall,
                  yWall + wallWidth,
                  wallWidth,
                  wallLength
                );
              }
            }
          }
        }
      }
      this.drawFinish();
      this.imgFinish.onload = () => {
        this.drawFinish();
      };
    },
    drawFinish() {
      const cellSize = this.playerWidth;
      const wallWidth = this.wallWidth;
      const row = this.maze.length;
      const column = this.maze[0].length;
      const xFinish =
        Math.floor(column / 2) * (cellSize + wallWidth) - cellSize;
      const yFinish =
        Math.floor(row / 2) * (cellSize + wallWidth) + 2 * wallWidth;
      this.context.drawImage(
        this.imgFinish,
        xFinish,
        yFinish,
        cellSize,
        cellSize
      );
    },
    drawPlayer() {
      const cellSize = this.playerWidth;
      const wallWidth = this.wallWidth;
      const marginPlayer = this.marginPlayer;
      var xPlayer, yPlayer;
      this.context.fillStyle = "#00f";

      this.context.shadowBlur = 0;
      this.context.shadowColor = "rgba(255, 255, 255, 0)"; // Warna bayangan (rgba)
      this.context.shadowOffsetX = 0; // Posisi bayangan pada sumbu X
      this.context.shadowOffsetY = 0;

      if (this.player.x % 2 == 1) {
        xPlayer =
          Math.floor(this.player.x / 2) * (cellSize + wallWidth) +
          wallWidth +
          marginPlayer;
      } else {
        xPlayer =
          Math.floor(this.player.x / 2) * (cellSize + wallWidth) +
          wallWidth +
          marginPlayer -
          cellSize / 2;
      }
      if (this.player.y % 2 == 1) {
        yPlayer =
          Math.floor(this.player.y / 2) * (cellSize + wallWidth) +
          wallWidth +
          marginPlayer;
      } else {
        yPlayer =
          Math.floor(this.player.y / 2) * (cellSize + wallWidth) +
          wallWidth +
          marginPlayer -
          cellSize / 2;
      }
      /*
      this.context.fillRect(
        xPlayer,
        yPlayer,
        cellSize - 2 * marginPlayer,
        cellSize - 2 * marginPlayer
      );
      */
      this.context.drawImage(
        this.imgPlayer,
        xPlayer,
        yPlayer,
        cellSize - 2 * marginPlayer,
        cellSize - 2 * marginPlayer
      );
    },
    clearPlayer() {
      const cellSize = this.playerWidth;
      const wallWidth = this.wallWidth;
      const marginPlayer = this.marginPlayer;
      var xPlayer, yPlayer;

      if (this.player.x % 2 == 1) {
        xPlayer =
          Math.floor(this.player.x / 2) * (cellSize + wallWidth) +
          wallWidth +
          marginPlayer;
      } else {
        xPlayer =
          Math.floor(this.player.x / 2) * (cellSize + wallWidth) +
          wallWidth +
          marginPlayer -
          cellSize / 2;
      }
      if (this.player.y % 2 == 1) {
        yPlayer =
          Math.floor(this.player.y / 2) * (cellSize + wallWidth) +
          wallWidth +
          marginPlayer;
      } else {
        yPlayer =
          Math.floor(this.player.y / 2) * (cellSize + wallWidth) +
          wallWidth +
          marginPlayer -
          cellSize / 2;
      }
      this.context.clearRect(
        xPlayer,
        yPlayer,
        cellSize - 2 * marginPlayer,
        cellSize - 2 * marginPlayer
      );
    },
    movePlayer(event) {
      const keyCode = event.keyCode;
      if (keyCode >= 37 && keyCode <= 40) {
        event.preventDefault();
      }
      this.movePlayerCode(keyCode);
    },
    movePlayerCode(code) {
      const keyCode = code;
      if (
        !this.gameover &&
        this.player.x <= this.maze[0].length - 2 &&
        this.player.y <= this.maze.length - 1
      ) {
        switch (keyCode) {
          case 37: // Left
            this.clearPlayer();
            this.moveLeft();
            break;
          case 38: // Up
            this.clearPlayer();
            this.moveUp();
            break;
          case 39: // Right
            this.clearPlayer();
            this.moveRight();
            break;
          case 40: // Down
            this.clearPlayer();
            this.moveDown();
            break;
          default:
            return;
        }
        //this.drawMaze();
        this.drawPlayer();

        // Check if the player reached the exit
        if (
          this.player.x === this.maze[0].length - 2 &&
          this.player.y === this.maze.length - 1
        ) {
          this.context.fillStyle = "rgba(0, 0, 0, 0.8)";
          this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
          this.gameover = true;
          clearInterval(this.counterInterval);
          this.counterInterval = null;
          this.simpanGames();
        }
      }
    },
    moveLeft() {
      if (this.maze[this.player.y][this.player.x - 1] !== 1) {
        this.player.x -= 1;
      }
    },
    moveUp() {
      if (this.maze[this.player.y - 1][this.player.x] !== 1) {
        this.player.y -= 1;
      }
    },
    moveRight() {
      if (this.maze[this.player.y][this.player.x + 1] !== 1) {
        this.player.x += 1;
      }
    },
    moveDown() {
      if (this.maze[this.player.y + 1][this.player.x] !== 1) {
        this.player.y += 1;
      }
    },
    newGame() {
      do {
        this.generateMaze();
        this.findShortestPath();
      } while (this.shortestPath.length > 200);
      this.player = { x: 1, y: 1 };
      this.drawMaze();
      this.drawPlayer();
      this.imgPlayer.onload = () => {
        this.drawPlayer();
      };
      this.gameover = false;
      this.counterTimer = this.counterTimerAwal;
      this.counterInterval = setInterval(this.countDownTimer, 1000);
    },
    async simpanGames() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/simpangames",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.$store.getters.getUserData.username,
              token: this.$store.getters.getUserData.token,
              games: "RUNNER",
              skor: this.counterTimer,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            console.log("Berhasil menyimpan skor data");
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    async getDataLeaderboard() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getleaderboard",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.$store.getters.getUserData.username,
              token: this.$store.getters.getUserData.token,
              games: "RUNNER",
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            this.leaderboardData = data.data;
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    formatWaktu(x) {
      var minutes, seconds, waktu;
      if (x == "-") {
        return x;
      } else {
        minutes = parseInt(x / 60, 10);
        seconds = parseInt(x % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        waktu = minutes + ":" + seconds;
      }
      return waktu;
    },
    async showLeaderboard() {
      await this.getDataLeaderboard();
      this.isLeaderboard = true;
    },
    hideLeaderboard() {
      this.isLeaderboard = false;
    },
  },
  mounted() {
    this.canvas = document.getElementById("runnercanvas");
    this.context = this.canvas.getContext("2d");
    this.imgPlayer.src = require("../assets/images/runner/player.png");
    this.imgFinish.src = require("../assets/images/runner/finish.png");
    this.imgTekstur.src = require("../assets/images/runner/brick2.png");
    const vw = window.innerWidth;
    if (vw >= 1920) {
      this.playerWidth = 50;
      this.wallWidth = this.playerWidth / 10;
      this.marginPlayer = this.wallWidth * 2;
      this.canvasHeight = 675;
    } else if (vw >= 1400 && vw < 1919) {
      this.playerWidth = 40;
      this.wallWidth = this.playerWidth / 10;
      this.marginPlayer = this.wallWidth * 2;
    } else if (vw >= 1280 && vw < 1400) {
      this.playerWidth = 30;
      this.wallWidth = this.playerWidth / 10;
      this.marginPlayer = this.wallWidth * 2;
    } else if (vw >= 768 && vw < 1279) {
      this.playerWidth = 20;
      this.wallWidth = this.playerWidth / 10;
      this.marginPlayer = this.wallWidth * 2;
    } else {
      this.playerWidth = 15;
      this.wallWidth = 2;
      this.marginPlayer = 2;
    }

    this.bgmSound.src = require("../assets/audio/bgmspeed.mp3");
    this.bgmSound.loop = true;
    this.bgmSound.volume = 0.6;
    try {
      if (this.bgmSound.paused)
        this.bgmSound.play().catch(() => {
          console.log("error bg sound");
        });
    } catch (err) {
      console.log("error");
    }

    this.newGame();
    window.addEventListener("keydown", this.movePlayer);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.movePlayer);
    clearInterval(this.counterInterval);
    this.counterInterval = null;
    try {
      this.bgmSound.pause().catch(() => { });
    } catch (err) {
      console.log("error stop bgm");
    }
  },
};
</script>
