<!-- components/HunterGames.vue -->
<!-- BUSUR GERAK, TARGET DIAM-->
<template>
  <div class="huntergames-top">
    <div class="huntergames-top-left">TIME : {{ this.counterTimerText }}</div>
    <div class="huntergames-top-mid">
      <img src="../assets/images/hunter/logo.png" />
    </div>
    <div class="huntergames-top-right">
      SCORE : {{ this.formatNumber(totalScore) }}
    </div>
  </div>
  <div class="huntergames-frame">
    <canvas id="huntercanvas"></canvas>
  </div>
  <div v-if="gameover" class="huntergames-text">
    GAME OVER !<br />
    Selamat Skor Anda : {{ this.formatNumber(totalScore) }}
    <div @click="startGame" class="huntergames-button">
      <img src="../assets/images/button_next.png" />
    </div>
  </div>
  <div class="huntergames-text">
    <span v-html="scoreComboText"></span>
  </div>
  <div class="huntergames-leaderboard" @click="showLeaderboard">
    <img src="../assets/images/speed/icon_leaderboard.png" />
  </div>
  <Transition>
    <div
      v-if="isLeaderboard"
      @click.self="hideLeaderboard"
      class="full-screen modal-v modal-leaderboard"
    >
      <div class="logo-top">
        <img src="../assets/images/hunter/logo.png" />
      </div>
      <div class="close-modal" @click="hideLeaderboard">
        <img src="../assets/images/close_icon.png" />
      </div>
      <div class="leaderboard-frame">
        <div class="leaderboard-header">LEADERBOARD</div>
        <div class="leaderboard-container">
          <div
            v-for="(leaderboard, index) in leaderboardData"
            :key="index"
            :class="leaderboard.class"
          >
            {{ leaderboard.nama }}
            <span class="leaderboard-score">
              {{ this.formatNumber(leaderboard.score) }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  data() {
    return {
      isStart: false,
      canvas: null,
      context: null,
      canvas2: null,
      context2: null,
      bgmSound: new Audio(),
      shootSound: new Audio(),
      hitSound: new Audio(),
      endSound: new Audio(),
      autoMove: false,
      canvasWidth: 0,
      canvasHeight: 0,
      totalScore: 0,
      scoreCombo: 0,
      scoreComboText: "",
      arrowState: 0,
      checkArrowMoveWithBoard1: false,
      checkArrowMoveWithBoard2: false,
      imgBusurPanah: new Image(),
      imgAnakPanah: new Image(),
      imgBoard: new Image(),
      defaultVal: {
        yArc: 100,
        dyArc: 3,
        arrowWidth: 85, // Arrow Width
        yBoard: this.canvasHeight / 4,
        dyBoard: 4,
        topBoard: 10,
        busurWidth: 58,
        busurHeight: 100,
      },
      arc: {
        x: 30,
        y: 100,
        dy: 3,
        r: 50,
        color: "#000",
        lw: 3,
        width: 85,
        height: 10,
        speed: 20,
        start: Math.PI + Math.PI / 2,
        end: Math.PI - Math.PI / 2,
      },
      rope: {
        lw: 1,
        color: "#000",
        status: true,
      },
      board: {
        dy: 4,
        height: 150,
        width: 43,
      },
      boardY: null,
      boardMove: false,
      arrow1: null,
      arrow2: null,
      intvRun: null,
      moveArrowCheck: false,
      gameover: false,
      counterTimerAwal: 120,
      counterTimer: this.counterTimerAwal,
      counterInterval: null,
      counterTimerText: "02:00",
      isLeaderboard: false,
      leaderboardData: [
        { class: "leaderboard-me", nama: "skor saya : ", score: 0 },
      ],
    };
  },
  methods: {
    countDownTimer() {
      var minutes, seconds;
      if (this.counterTimer < 0) {
        this.counterTimerText = "00:00";
        if (!this.moveArrowCheck) {
          this.isStart = false;
          this.context.fillStyle = "rgba(0, 0, 0, 0.8)";
          this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
          this.gameover = true;
          clearInterval(this.intvRun);
          this.intvRun = null;
          this.simpanGames();
          clearInterval(this.counterInterval);
          this.counterInterval = null;
        }
      } else {
        minutes = parseInt(this.counterTimer / 60, 10);
        seconds = parseInt(this.counterTimer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.counterTimerText = minutes + ":" + seconds;
        this.counterTimer--;
      }
    },
    startGame() {
      clearInterval(this.intvRun);
      this.intvRun = null;
      clearInterval(this.counterInterval);
      this.counterInterval = null;

      this.isStart = true;
      this.gameover = false;
      this.loadGame();
    },
    drawBoard() {
      this.context.drawImage(
        this.imgBoard,
        this.board.x,
        this.board.y - this.board.height / 2,
        this.board.width,
        this.board.height
      );
      //100 >= 200 || 100 < 100
      if (
        this.board.y >= this.canvasHeight - this.defaultVal.yBoard / 2 ||
        this.board.y <= this.defaultVal.yBoard / 2
      ) {
        this.board.dy *= -1;
      }

      if (this.autoMove) {
        this.board.y += this.board.dy;
        if (this.checkArrowMoveWithBoard1) {
          this.moveArrowWithBoard(this.arrow1, 1);
        } else if (this.checkArrowMoveWithBoard2) {
          this.moveArrowWithBoard(this.arrow2, 1);
        }
      } else {
        if (this.boardMove) {
          if (Math.abs(this.board.y - this.boardY) >= 3) {
            this.board.y += this.board.dy;
            if (this.checkArrowMoveWithBoard1) {
              this.moveArrowWithBoard(this.arrow1, 1);
            } else if (this.checkArrowMoveWithBoard2) {
              this.moveArrowWithBoard(this.arrow2, 1);
            }
          }
        } else {
          if (Math.abs(this.board.y - this.boardY) >= 3) {
            this.board.y -= this.board.dy;
            if (this.checkArrowMoveWithBoard1) {
              this.moveArrowWithBoard(this.arrow1, -1);
            } else if (this.checkArrowMoveWithBoard2) {
              this.moveArrowWithBoard(this.arrow2, -1);
            }
          }
        }
      }
    },
    move() {
      this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      if (
        this.arc.y > this.canvasHeight - this.defaultVal.yArc / 2 ||
        this.arc.y < this.defaultVal.yArc / 2
      ) {
        this.arc.dy *= -1;
      }
      this.arc.y += this.arc.dy;
    },
    drawArc() {
      this.context.drawImage(
        this.imgBusurPanah,
        this.arc.x - this.arc.width / 3,
        this.arc.y - this.defaultVal.busurHeight / 2,
        this.defaultVal.busurWidth,
        this.defaultVal.busurHeight
      );
    },
    drawRope() {
      this.context.beginPath();
      //this.context.fillStyle = "green";
      this.context.moveTo(this.arc.x, this.arc.y - this.arc.r);
      if (this.arrow1.vis && this.arrow2.vis) {
        this.context.lineTo(this.rope.x, this.arc.y);
      }
      this.context.lineTo(this.arc.x, this.arc.y + this.arc.r);
      this.context.lineWidth = this.rope.lw;
      this.context.strokeStyle = this.rope.color;
      this.context.stroke();
      this.context.closePath();
    },
    drawArrow(Arrow) {
      if (Arrow.vis && this.isStart) {
        if (Arrow.status) {
          this.context.drawImage(
            this.imgAnakPanah,
            Arrow.x,
            Arrow.fy - Arrow.h / 2,
            Arrow.w,
            Arrow.h
          );
          if (this.moveArrowCheck) {
            var minBoard =
              this.board.y -
              this.board.height / 2 +
              Arrow.h +
              this.defaultVal.topBoard;
            var maxBoard = this.board.y + this.board.height / 2 - Arrow.h;
            if (
              Arrow.x <
              this.canvasWidth -
                (this.defaultVal.arrowWidth + this.board.width / 2)
            ) {
              Arrow.x += Arrow.dx;
            } else {
              if (
                !(Arrow.fy < minBoard || Arrow.fy > maxBoard) ||
                Arrow.x > this.canvasWidth
              ) {
                if (
                  Arrow.x >=
                  this.canvasWidth -
                    (this.defaultVal.arrowWidth + this.board.width / 2)
                ) {
                  if (Arrow == this.arrow1) {
                    this.arrow2.vis = true;
                    this.checkArrowMoveWithBoard1 = true;
                    this.checkArrowMoveWithBoard2 = false;
                  } else {
                    this.arrow1.vis = true;
                    this.checkArrowMoveWithBoard1 = false;
                    this.checkArrowMoveWithBoard2 = true;
                  }
                  this.moveArrowCheck = false;

                  if (Arrow.fy >= minBoard && Arrow.fy <= maxBoard) {
                    try {
                      this.hitSound.play().catch(() => {});
                    } catch (err) {
                      console.log("error sound");
                    }
                    var scores = Arrow.fy - this.board.y;
                    var currentScore =
                      Math.round(this.board.height / 20) -
                      Math.round(Math.abs(scores / 10));
                    var bonusPoin, poinShoot;

                    this.scoreCombo++;
                    bonusPoin = 10 * this.scoreCombo;

                    if (currentScore >= 7) {
                      poinShoot = 100;
                    } else if (currentScore >= 5) {
                      poinShoot = 80;
                    } else if (currentScore >= 3) {
                      poinShoot = 60;
                    } else {
                      poinShoot = 40;
                    }
                    if (this.scoreCombo > 1) {
                      this.totalScore += poinShoot + bonusPoin;
                      this.scoreComboText =
                        "COMBO x" +
                        this.scoreCombo +
                        "<br/>" +
                        "Poin + " +
                        poinShoot +
                        "<br/>" +
                        "Bonus poin + " +
                        bonusPoin;
                    } else {
                      this.totalScore += poinShoot;
                      this.scoreComboText = "Poin + " + poinShoot;
                    }
                    setTimeout(() => {
                      this.scoreComboText = "";
                    }, 1000);
                    //this.totalScore += currentScore;
                    //animateScore(currentScore, totalArr);
                    
                  } else {
                    this.scoreComboText = "MISS";
                    setTimeout(() => {
                      this.scoreComboText = "";
                    }, 1000);
                    this.scoreCombo = 0;
                    Arrow.x += Arrow.dx;
                  }
                } else {
                  Arrow.x += Arrow.dx;
                }
              } else {
                Arrow.x += Arrow.dx;
              }
            }
          }
        } else {
          this.context.drawImage(
            this.imgAnakPanah,
            Arrow.x,
            this.arc.y - Arrow.h / 2,
            Arrow.w,
            Arrow.h
          );
        }
      }
    },
    moveArrowWithBoard(Arrow, dir) {
      if (Arrow == this.arrow1) {
        this.arrow1.fy += this.board.dy * dir;
      } else {
        this.arrow2.fy += this.board.dy * dir;
      }
    },
    runIntv() {
      this.move();
      this.drawArc();
      //this.drawRope();
      this.drawBoard();
      this.drawArrow(this.arrow1);
      this.drawArrow(this.arrow2);
    },
    loadGame() {
      // DEKLARASI AWAL
      this.rope.x = this.arc.x - this.arc.width / 3;
      this.board.x = this.canvasWidth - this.board.width;
      this.board.y = this.canvasHeight / 2;
      this.board.dy = this.defaultVal.dyBoard;

      this.boardY = this.board.y;
      this.arc.y = this.defaultVal.yArc;
      this.arc.dy = this.defaultVal.dyArc;
      this.arrowState = 0;
      this.totalScore = 0;
      this.autoMove = false;
      this.boardMove = false;
      this.arrow1 = new Arrow(this.arc);
      this.arrow2 = new Arrow(this.arc);

      this.intvRun = setInterval(this.runIntv, 10);

      this.counterTimer = this.counterTimerAwal;
      this.counterInterval = setInterval(this.countDownTimer, 1000);
    },
    shoot() {
      if (!this.moveArrowCheck && !this.gameover) {
        if (this.arrow1.vis && this.arrow2.vis && this.arrowState != -1) {
          this.moveArrowCheck = true;
          if (this.arrowState % 2 === 0) {
            this.arrow1.status = true;
            this.arrow2.status = false;
            this.arrow1.fy = this.arc.y;
            this.arrow2.x = this.rope.x;
            this.arrow2.vis = false;
          } else {
            this.arrow1.status = false;
            this.arrow2.status = true;
            this.arrow2.fy = this.arc.y;
            this.arrow1.x = this.rope.x;
            this.arrow1.vis = false;
          }
          try {
            this.shootSound.play().catch(() => {});
          } catch (err) {
            console.log("error sound");
          }
        }
        this.arrowState++;
      }
    },
    async simpanGames() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/simpangames",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.$store.getters.getUserData.username,
              token: this.$store.getters.getUserData.token,
              games: "HUNTER",
              skor: this.totalScore,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            console.log("Berhasil menyimpan skor data");
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    async getDataLeaderboard() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getleaderboard",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.$store.getters.getUserData.username,
              token: this.$store.getters.getUserData.token,
              games: "HUNTER",
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            this.leaderboardData = data.data;
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    formatNumber(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    },
    async showLeaderboard() {
      await this.getDataLeaderboard();
      this.isLeaderboard = true;
    },
    hideLeaderboard() {
      this.isLeaderboard = false;
    },
  },
  mounted() {
    this.bgmSound.src = require("../assets/audio/bgmspeed.mp3");
    this.shootSound.src = require("../assets/audio/arrow.mp3");
    this.hitSound.src = require("../assets/audio/arrowhit.mp3");
    this.bgmSound.loop = true;
    this.bgmSound.volume = 0.6;
    try {
      if (this.bgmSound.paused)
        this.bgmSound.play().catch(() => {
          console.log("error bg sound");
        });
    } catch (err) {
      console.log("error");
    }

    this.imgBusurPanah.src =
      "https://admin.fl-fifastra.com/assets/images/hunter/panahsa.png";
    this.imgAnakPanah.src =
      "https://admin.fl-fifastra.com/assets/images/hunter/busursa.png";
    this.imgBoard.src =
      "https://admin.fl-fifastra.com/assets/images/hunter/targetsa.png";

    const huntercanvas = document.getElementById("huntercanvas");
    this.canvas = huntercanvas;
    this.context = huntercanvas.getContext("2d");
    const vw = window.innerWidth;
    if (vw >= 1920) {
      this.canvasWidth = 1500;
      this.canvasHeight = 675;
    } else if (vw >= 1400 && vw < 1919) {
      this.canvasWidth = 1200;
      this.canvasHeight = 540;
    } else if (vw >= 1280 && vw < 1400) {
      this.canvasWidth = 1000;
      this.canvasHeight = 450;
    } else if (vw >= 768 && vw < 1279) {
      this.canvasWidth = 600;
      this.canvasHeight = 270;
    } else {
      this.canvasWidth = 400;
      this.canvasHeight = 180;
    }
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;
    this.board.height = this.defaultVal.yBoard = this.canvas.height / 3;
    this.defaultVal.yArc = this.canvas.height / 4;
    this.board.width = this.board.height * 0.3;

    this.arc.width = this.defaultVal.arrowWidth = this.canvas.width / 10;
    this.arc.height = this.arc.width / 11;
    this.arc.speed = this.canvas.width / 40;

    this.defaultVal.busurHeight = this.canvas.height / 3;
    this.defaultVal.busurWidth = (this.defaultVal.busurHeight * 58) / 100;
    this.defaultVal.dyArc = this.canvas.height / 100;
    this.defaultVal.dyBoard = this.canvas.height / 150;

    this.canvas.addEventListener("click", this.shoot);
    document.body.addEventListener("keydown", this.shoot);

    this.startGame();
  },
  beforeUnmount() {
    this.canvas.removeEventListener("click", this.shoot);
    document.body.removeEventListener("keydown", this.shoot);
    clearInterval(this.intvRun);
    this.intvRun = null;
    clearInterval(this.counterInterval);
    this.counterInterval = null;

    try {
      this.bgmSound.pause().catch(() => {});
    } catch (err) {
      console.log("error stop bgm");
    }
  },
};

class Arrow {
  constructor(arc) {
    this.w = arc.width; // 85; // Arrow Width
    this.h = arc.height; // 10; // Arrow Height
    this.x = arc.x - arc.width / 3;
    this.dx = arc.speed; // 20; // Arrow speed
    this.status = false;
    this.vis = true;
    this.fy = arc.y;
  }
}
</script>
