// store.js
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const persistedState = createPersistedState({
  key: "fifgame", // Nama kunci untuk menyimpan state di localStorage
  storage: window.localStorage, // gunakan window.sessionStorage untuk sessionStorage
});

export default createStore({
  plugins: [persistedState],
  state: {
    isAuthenticated: false,
    sessionTimeout: null,
    userData: {
      username: "",
      token: "",
      nama: "",
      game: "",
      rank: "",
    },
  },
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
    setSessionTimeout(state, timeout) {
      state.sessionTimeout = timeout;
    },
    setUserData(state, userData) {
      state.userData = userData;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.userData = {
        username: "",
        token: "",
        nama: "",
        games: "",
        rank: "",
      };
    },
    updateAppClass(state, newClass) {
      state.mainClass = newClass;
    },
  },
  actions: {
    login({ commit }, userData) {
      // Lakukan logika login
      commit("setAuthentication", true);
      commit("setUserData", userData);
      commit("setSessionTimeout", 30);
    },
    logout({ commit }) {
      // Lakukan logika logout
      commit("logout");
    },
    setSessionTimeout({ commit, dispatch }, timeout) {
      // Set session timeout and trigger logout after the specified time
      const timeoutId = setTimeout(() => {
        dispatch("logout");
      }, timeout);
      // Save timeout ID to state
      commit("setSessionTimeout", timeoutId);
    },
    resetSessionTimeout({ state, dispatch }) {
      // Reset session timeout when there is user activity
      if (state.sessionTimeout) {
        clearTimeout(state.sessionTimeout);
        dispatch("setSessionTimeout", 30 * 60 * 1000);
      }
    },
    setUserData({ commit }, userData) {
      commit("setUserData", userData);
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    getUserData: (state) => state.userData,
  },
});
