<template>
  <div id="mainApp" :class="mainClass">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    mainClass() {
      return this.$store.state.mainClass;
    },
  }
}
</script>

<style>
</style>
