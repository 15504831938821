<template>
  <div v-if="isPortrait">
    <div class="full-screen">
      <span class="text-portrait">Putar layar HP anda ke mode Landscape</span>
    </div>
  </div>

  <div v-if="!isPortrait">
    <Transition name="fade">
      <div
        v-if="showWelcomeScreen"
        @click="enterMainView"
        class="full-screen"
        id="splash"
      >
        <div class="splash-main">
          <img
            :class="classSplashLogo"
            :src="require(`../assets/images/` + srcSplashLogo)"
          />
        </div>
        <div
          v-for="(cloud, index) in splashItems"
          :key="index"
          :class="cloud.class"
        >
          <img
            v-for="(cld, idx) in cloud.cloudImg"
            :key="idx"
            :class="cld.class"
            :src="require(`../assets/images/awan/` + cld.src)"
            :alt="cld.alt"
          />
        </div>
      </div>
    </Transition>
    <Transition name="fade">
      <div v-if="!showWelcomeScreen" id="mainScreen">
        <div
          v-if="showWelcomeText"
          class="full-screen flex-center"
          @click="enterDashboard"
        >
          <div class="text-erg text-welcome scale-in-welcome">
            <img :src="require(`../assets/images/` + srcSplashWelcome)" />
          </div>
        </div>
        <div v-if="!showWelcomeText" class="full-screen">
          <Transition name="fade">
            <div v-if="isPromosiAwal" class="full-screen modal-v">
              <img src="../assets/images/promo-fl-april.jpg" @click="hidePromosiAwal" />
              <div class="close-modal" @click="hidePromosiAwal">
                <img src="../assets/images/close_icon.png" />
              </div>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="isGimmick" class="full-screen modal-v">
              <div class="video-frame">
                <div class="vid-panduan">
                  <img src="../assets/images/video/panduan.png" />
                </div>
                <video class="vid-video" preload="auto" autoplay controls>
                  <source
                    :src="require(`../assets/video/gimmick/` + videoGimmick)"
                    type="video/mp4"
                  />
                </video>
                <div class="vid-skip" @click="hideGimmick">
                  <img src="../assets/images/video/buttonx.png" />
                </div>
              </div>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="isTutorialOne" class="full-screen modal-v">
              <div class="video-frame">
                <div class="vid-panduan">
                  <img src="../assets/images/video/panduan.png" />
                </div>
                <video class="vid-video" preload="auto" autoplay controls>
                  <source
                    :src="require(`../assets/video/` + videoTutorialOne)"
                    type="video/mp4"
                  />
                </video>
                <div class="vid-skip" @click="hideTutorialOne">
                  <img src="../assets/images/video/buttonx.png" />
                </div>
              </div>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="isTutorialTwo" class="full-screen modal-v">
              <div class="video-frame">
                <div class="vid-panduan">
                  <img src="../assets/images/video/panduan.png" />
                </div>
                <video class="vid-video" preload="auto" autoplay controls>
                  <source
                    :src="require(`../assets/video/` + videoTutorialTwo)"
                    type="video/mp4"
                  />
                </video>
                <div class="vid-skip" @click="hideTutorialTwo">
                  <img src="../assets/images/video/buttonx.png" />
                </div>
              </div>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="showAudio">
              <audio id="audioLoop" autoplay loop :volume="audioVolume">
                <source
                  :src="require(`../assets/audio/` + srcBacksound)"
                  type="audio/mp3"
                />
              </audio>
            </div>
          </Transition>

          <div class="profil-button">
            <img src="../assets/images/user.png" />
            <span v-if="showTypeWriter" class="text-erg text-profil"
              >{{ textWriterAtas }}<br />
              {{ textWriterBawah }}</span
            >
            <!--a href="#" @click.prevent="">LOGOUT</a-->
          </div>
          <div class="logo-top">
            <img :src="require(`../assets/images/` + srcSplashLogo)" />
          </div>
          <div class="notif-button">
            <!--img src="../assets/images/notif.png" /-->
            <img @click="logout" src="../assets/images/logoutx.png" />
          </div>
          <div :class="leftButtonMenu">
            <div class="arrow-button">
              <img
                @click="hideLeftMenu"
                src="../assets/images/speed/arrow.png"
              />
            </div>
            <div class="square-button" style="margin-bottom: 10%">
              <img
                @click="showPusatInformasi"
                src="../assets/images/button/pusat_info.png"
              />
            </div>
            <div class="square-button">
              <img
                @click="showPromoModal"
                src="../assets/images/button/promo.png"
              />
            </div>
            <div class="square-button">
              <img @click="showGames" src="../assets/images/button/game.png" />
            </div>
            <div class="square-button">
              <img
                @click="showCreditScore"
                src="../assets/images/button/monthly_score.png"
              />
            </div>
          </div>
          <div :class="leftButtonArrow">
            <div class="arrow-button" @click="showLeftMenu">
              <img src="../assets/images/speed/arrow.png" />
            </div>
          </div>
          <div class="play-button" @click="showChart">
            <img src="../assets/images/button/button.png" />
            <span class="text-play text-erg"
              >P E R F O R M A N C E<br />
              <!--hr /-->
              {{ this.userProgres }}</span
            >
          </div>
          <!--div class="right-button">
          <div class="square-button" @click="showTutorial">
            <img src="../assets/images/themostrunner/tutor.png" />
          </div>
          <div class="square-button">
            <img src="../assets/images/themostrunner/games.png" />
          </div>
        </div-->
          <div class="island-frame">
            <div :class="classIsland">
              <div class="circle-progress" data-progress="50"></div>
              <!--img
              class="planet-here"
              src="../assets/images/themostrunner/here.png"
              :style="planetHereStyle"
            /-->
              <div class="container-planet">
                <div
                  v-for="(planet, index) in planetItems"
                  :key="index"
                  :class="planet.class"
                  :style="planet.style"
                  :title="planet.idPlanet + ' : ' + planet.progresPersen + ' %'"
                  @click="showPlanetPopUp(planet.idPlanet)"
                >
                  <img
                    class="planet-progress-planet"
                    :src="require(`../assets/images/` + planet.imgPlanet)"
                  />
                  <img
                    class="planet-progress-badge"
                    :src="
                      require(`../assets/images/rank/` + planet.planetBadge)
                    "
                  />
                  <div class="planet-value" ref="circleValue">
                    {{ planet.progresPersen }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Transition>
          <div
            v-if="showQuestModal"
            class="full-screen modal-v"
            @click.self="hideQuest"
          >
            <div class="close-modal" @click="hideQuest">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="quest-frame">
              <div class="text-lg-mid text-xl">DAILY QUEST</div>
              <div
                v-for="(item, index) in dailyQuestItem"
                class="octagon-input-container"
                :key="index"
                @click.stop="handleItemClick(item.nama, index)"
              >
                <div class="octagon-input-line bg-quest-line">
                  <div class="octagon-input text-left bg-quest-text">
                    <div class="text-left">{{ item.nama }}</div>
                    <div class="arrow-right">
                      <img src="../assets/images/arrow.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div
            v-if="showChartModal"
            class="full-screen modal-v"
            @click.self="hideChart"
          >
            <div class="close-modal" @click="hideChart">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="chart-frame">
              <div class="text-chart">
                PERFORMANCE DEALER <br />
                {{ textNama }}
              </div>
              <div class="chart-container">
                <Line
                  :data="chartLabel"
                  :options="chartOptions"
                  :plugins="chartPlugins"
                  ref="chartPerformance"
                />
              </div>
              <div class="chart-gridlabel">
                <div class="chart-gridlabel-1">
                  Target :
                  {{ this.formatNumber(chartLabel.datasets[2]?.data[0]) ?? 0 }}
                  <br />Actual :
                  {{ this.formatNumber(chartLabel.datasets[1]?.data[0]) ?? 0 }}
                </div>
                <div class="chart-gridlabel-2">
                  Target : {{ chartLabel.datasets[2]?.data[1] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[1] ?? 0 }}
                </div>
                <div class="chart-gridlabel-3">
                  Target : {{ chartLabel.datasets[2]?.data[2] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[2] ?? 0 }}
                </div>
                <div class="chart-gridlabel-4">
                  Target : {{ chartLabel.datasets[2]?.data[3] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[3] ?? 0 }}
                </div>
                <div class="chart-gridlabel-5">
                  Target : {{ chartLabel.datasets[2]?.data[4] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[4] ?? 0 }}
                </div>
                <div class="chart-gridlabel-6">
                  Target : {{ chartLabel.datasets[2]?.data[5] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[5] ?? 0 }}
                </div>
                <div class="chart-gridlabel-7">
                  Target : {{ chartLabel.datasets[2]?.data[6] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[6] ?? 0 }}
                </div>
                <div class="chart-gridlabel-8">
                  Target : {{ chartLabel.datasets[2]?.data[7] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[7] ?? 0 }}
                </div>
              </div>
              <div class="text-totallabel">
                Total = {{ this.formatNumber(totalActual) }} ({{
                  totalProsen
                }}
                %) <br />Actual = {{ this.formatNumber(totalTarget) }}
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="showTutorialModal" class="full-screen modal-v">
            <div class="video-frame">
              <div class="vid-panduan">
                <img src="../assets/images/video/panduan.png" />
              </div>
              <div class="vid-play">
                <!--img src="../assets/images/video/button playx.png" /-->
              </div>
              <div class="vid-skip" @click="hideTutorial">
                <img src="../assets/images/video/buttonx.png" />
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div
            v-if="isPusatInformasi"
            @click.self="hidePusatInformasi"
            class="full-screen modal-v"
          >
            <div class="close-modal" @click="hidePusatInformasi">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="pusatinfo-frame">
              <div class="pusatinfo-container">
                <div class="text-pusatinfo">PUSAT INFORMASI</div>
                <div class="box-pusatinfo" @click="showTutorialOne">
                  <span>Video Program</span>
                </div>
                <div class="box-pusatinfo" @click="showTutorialTwo">
                  <span>Video Tutorial</span>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div
            v-if="isPromoModal"
            @click.self="hidePromoModal"
            class="full-screen modal-v"
          >
            <div class="close-modal" @click="hidePromoModal">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="pusatinfo-frame">
              <div class="pusatinfo-container">
                <div class="text-pusatinfo">PROMO</div>
                <a href="https://fifgroup.co.id/fifastra/promosi"
                  ><div class="box-promo"><span>ALL PROMO</span></div></a
                >
                <div class="box-promo">
                  <span>UNDUH PROMO</span>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div
            v-if="isCreditScore"
            @click.self="hideCreditScore"
            class="full-screen modal-v"
          >
            <div class="close-modal" @click="hideCreditScore">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="creditlog-frame">
              <div class="creditlog-header">{{ bulanCreditScore }}</div>
              <div class="creditlog-container">
                <table class="creditlog-table">
                  <thead>
                    <th>SEN</th>
                    <th>SEL</th>
                    <th>RAB</th>
                    <th>KAM</th>
                    <th>JUM</th>
                    <th>SAB</th>
                    <th>MIN</th>
                  </thead>
                  <tr v-for="(tanggalan, index) in creditMonthly" :key="index">
                    <td v-for="(hari, index) in tanggalan" :key="index">
                      <img
                        v-if="hari !== null"
                        class="creditlog-img"
                        :src="require(`../assets/images/creditlog/` + hari)"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <span class="creditlog-text"
                >{{ creditMonthlyAktif }} / {{ totalDayMonth }}</span
              >
              <div class="creditlog-redeem">
                <img src="../assets/images/creditlog/redeem.png" />
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div
            v-if="isComingSoon"
            @click.self="hideComingSoon"
            class="full-screen modal-v"
          >
            <div class="close-modal" @click="hideComingSoon">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="comingsoon-frame">
              <div class="text-comingsoon">
                COMING SOON
                <!--img src="../assets/images/comingsoon/comingsoon.png" /-->
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div
            v-if="isPlanetPopUp"
            @click.self="hidePlanetPopUp"
            class="full-screen modal-v"
          >
            <div class="close-modal" @click="hidePlanetPopUp">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="planetpopup-frame">
              <div class="planetpopup-header">{{ planetPopUpHeader }}</div>
              <div class="planetpopup-kiri">
                <div :class="planetPopupClass" :style="planetPopUpStyle">
                  <img
                    class="planetpopup-planet"
                    :src="require(`../assets/images/` + planetPopUpSrc)"
                  />
                  <img
                    class="planetpopup-badge"
                    :src="require(`../assets/images/rank/` + planetPopUpBadge)"
                  />
                  <div class="planetpopup-value">{{ planetPopUpPersen }} %</div>
                </div>
              </div>
              <div class="planetpopup-kanan">
                <div>
                  <table>
                    <tr>
                      <td>TARGET</td>
                      <td>:</td>
                      <td>{{ planetPopUpTarget }}</td>
                    </tr>
                    <tr>
                      <td>ACTUAL</td>
                      <td>:</td>
                      <td>{{ planetPopUpCapaian }}</td>
                    </tr>
                    <tr>
                      <td>ACHIEVEMENT</td>
                      <td>:</td>
                      <td>{{ planetPopUpPersen }} %</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isSpeedGames" class="full-screen speed-style modal-speed">
            <div class="close-modal speed" @click="hideGames">
              <img src="../assets/images/close_icon.png" />
            </div>
            <SpeedGames />
          </div>
        </Transition>
        <Transition>
          <div
            v-if="isHunterGames"
            class="full-screen huntergames-style modal-hunter"
          >
            <div class="close-modal hunter" @click="hideGames">
              <img src="../assets/images/close_icon.png" />
            </div>
            <HunterGames />
          </div>
        </Transition>
        <Transition>
          <div
            v-if="isRunnerGames"
            class="full-screen runnergames-style modal-runner"
          >
            <div class="close-modal runner" @click="hideGames">
              <img src="../assets/images/close_icon.png" />
            </div>
            <RunnerGames />
          </div>
        </Transition>
      </div>
    </Transition>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
import SpeedGames from "./SpeedGamesEasy.vue";
import HunterGames from "./HunterGamesEasy.vue";
import RunnerGames from "./RunnerGamesTembok.vue";

const pointImage = new Image();
//pointImage.src = "http://admin.fl-fifastra.com/assets/images/pointstyle.png";
pointImage.src = require("../assets/images/chart/pointstyle.png");
pointImage.width = window.innerWidth / 20;
pointImage.height = pointImage.width;
export default {
  name: "MainScreen",
  components: {
    Line,
    SpeedGames,
    HunterGames,
    RunnerGames,
  },
  data() {
    return {
      username: this.$store.getters.getUserData.username,
      message: "",
      classSplashLogo: "splash-logo-main",
      classIsland: "island island-speed",
      textNama: this.$store.getters.getUserData.nama,
      textWriterAtas: "",
      textWriterBawah: "",
      textAtasPos: 0,
      textBawahPos: 0,
      userGames: this.$store.getters.getUserData.games,
      userRank: "Challenger",
      userProgres: "0%",
      writeSpeed: 200,
      i: 0,
      showTypeWriter: false,
      planetPopUpHeader: "januari 2024",
      planetPopupClass: "planetpopup-container",
      planetPopUpStyle: "",
      planetPopUpSrc: "speed/point.png",
      planetPopUpBadge: "chalenger.png",
      planetPopUpTarget: 0,
      planetPopUpCapaian: 0,
      planetPopUpPersen: 0,
      srcSplashLogo: "speed/logo.png",
      srcSplashWelcome: "speed/welcome.png",
      srcPlanetActive: "speed/point.png",
      srcPlanetInactive: "speed/point-inactive.png",
      srcBacksound: "speed-music.mp3",
      audioVolume: 0.5,
      showWelcomeScreen: true,
      showWelcomeText: true,
      showQuestModal: false,
      showChartModal: false,
      showTutorialModal: false,
      isPromosiAwal: false,
      isGimmick: false,
      isTutorialOne: true,
      isNextTutorialTwo: true,
      isTutorialTwo: false,
      videoGimmick: "",
      videoTutorialOne: "talentspeed.mp4",
      videoTutorialTwo: "tutorialspeed.mp4",
      isPusatInformasi: false,
      isPromoModal: false,
      isCreditScore: false,
      isComingSoon: false,
      isPlanetPopUp: false,
      isSpeedGames: false,
      isHunterGames: false,
      isRunnerGames: false,
      showAudio: false,
      isPortrait: window.innerWidth < 576,
      leftButtonMenu:
        window.innerWidth > 767
          ? "left-button menu-in"
          : "left-button menu-out",
      leftButtonArrow:
        window.innerWidth > 767
          ? "hide-button menu-out"
          : "hide-button menu-in",
      bulanCreditScore: "",
      creditMonthly: [],
      creditMonthlyAktif: 0,
      totalDayMonth: 0,
      splashItems: [
        {
          class: "splash-cloud-left",
          cloudImg: [
            {
              class: "cloud-left-1",
              src: `awan.png`,
              alt: "cloud",
            },
          ],
        },
        {
          class: "splash-cloud-right",
          cloudImg: [
            {
              class: "cloud-right-1",
              src: `awan.png`,
              alt: "cloud",
            },
          ],
        },
      ],
      planetHereStyle: `top: 41%; left: 82.5%;`, // TOP-4 || LEFT + 3.5
      planetItems: [
        {
          class: "planet-progress",
          idPlanet: "merkurius-123",
          style: `top: 47%; left: 78%;`,
          progresPersen: 0,
          imgPlanet: "speed/point.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
        {
          class: "planet-progress",
          idPlanet: "venus-123",
          style: `top: 52%; left: 60%;`,
          progresPersen: 0,
          imgPlanet: "speed/point.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "bumi-123",
          style: `top: 49%; left: 25%;`,
          progresPersen: 0,
          imgPlanet: "speed/point-inactive.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "mars-123",
          style: `top: 38%; left: 11%;`,
          progresPersen: 0,
          imgPlanet: "speed/point-inactive.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "saturnus-123",
          style: `top: 35%; left: 31%;`,
          progresPersen: 0,
          imgPlanet: "speed/point-inactive.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "jupiter-123",
          style: `top: 33%; left: 48%;`,
          progresPersen: 0,
          imgPlanet: "speed/point-inactive.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "uranus-123",
          style: `top: 24%; left: 66%;`,
          progresPersen: 0,
          imgPlanet: "speed/point-inactive.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "neptunus-123",
          style: `top: 13%; left: 50%;`,
          progresPersen: 0,
          imgPlanet: "speed/point-inactive.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "pluto-123",
          style: `top: 13%; left: 50%;`,
          progresPersen: 0,
          imgPlanet: "speed/point-inactive.png",
          srcRank: "chalenger.png",
          planetBadge: "chalenger.png",
        },
      ],
      dailyQuestItem: [
        { nama: "PLAY THE GAME TODAY" },
        { nama: "WATCH THE FOLLOWING VIDEO" },
        { nama: "LIKE AND POST" },
      ],
      totalActual: 0,
      totalTarget: 0,
      totalProsen: 0,
      chartLabel: {
        labels: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          /*"SEP",
          "OKT",
          "NOV",
          "DES",*/
        ],
        datasets: [
          {
            label: false,
            borderColor: "#ffffff", // warna garis
            pointBackgroundColor: "#fff0",
            pointBorderColor: "#fff",
            pointStyle: pointImage,
            pointRadius: 10,
            pointHoverRadius: 15,
            borderWidth: 2,
            /*radius: 0,*/
            fill: false,
            /*
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0, 0, 0, 160);

              gradient.addColorStop(0, "#c9ccdb");
              gradient.addColorStop(0.5, "#8b97e6");
              gradient.addColorStop(1, "#0b29e4");

              return gradient;
            },
            barThickness: 50,*/
            data: [],
          },
          {
            label: false,
            hidden: true,
            data: [],
          },
          {
            label: false,
            hidden: true,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            text:
              "PERFORMANCE DEALER \n" + this.$store.getters.getUserData.nama,
            font: {
              size: 25,
              weight: "bold",
              color: "white",
              padding: {
                top: 10,
                bottom: 20,
              },
            },
          },
          datalabels: {
            anchor: "end",
            align: "end",
            clamp: true,
            color: "#7c9196",
            backgroundColor: "white",
            borderRadius: 50,
            padding: 5,
            formatter: function (value) {
              return value + " %";
            },
            display: "auto",
          },
          tooltip: {
            enabled: false,
            displayColors: false,
            /*callbacks: {
              title: function (data) {
                const value = data[0].raw;
                //return "";
                if (value >= 100) {
                  return "MELEBIHI TARGET";
                } else {
                  return "";
                }
              },
              label: function (context) {
                //const bulan = context.label;
                //const value = context.parsed.y;
                const datasets = context.chart.data.datasets;
                const nilaiAsli = datasets[1].data[context.dataIndex];
                const targetAsli = datasets[2].data[context.dataIndex];
                //const persenAsli = datasets[2].data[context.dataIndex];
                //return `${bulan} : ${nilaiAsli} Penjualan (${persenAsli} %)`;
                return `Target : ${targetAsli} || Achievement : ${nilaiAsli}`;
              },
              labelPointStyle: function (context) {
                console.log(context);
                const pointImage = new Image();
                pointImage.src;
                pointImage.src = "https://img.icons8.com/bubbles/30/star.png";
                  return { pointStyle: pointImage };
                /*const value = context.parsed.y;
                if (value >= 80) {
                  return { pointStyle: "rectRot" };
                } else {
                  return { pointStyle: "rectRot" };
                }

              },
            },*/
          },
        },
        scales: {
          x: {
            ticks: {
              // tulisan y
              color: "white",
              backgroundColor: "green",
              font: {
                size: 15,
                weight: "bold",
              },
              padding: 30,
            },
          },
          y: {
            min: 0,
            max: 180,
            borderColor: "blue",
            ticks: {
              // tulisan y
              color: "white",
              font: {
                size: 12,
                weight: "bold",
              },
              padding: 15,
            },
          },
        } /*
        layout: {
          padding: {
            top: 50,
          },
        },*/,
      },
      chartPlugins: [
        {
          afterDraw: (chart) => {
            //console.log(chart);
            const ctx = chart.ctx;
            const xAxis = chart.scales["x"];
            const yAxis = chart.scales["y"];

            //imageBawah.src = "https://img.icons8.com/bubbles/30/star.png";
            xAxis.ticks.forEach((value, index) => {
              let x = xAxis.getPixelForTick(index);
              // nanti mengambil image berdasar index
              var imageBawah = new Image();
              imageBawah.src =
                "https://admin.fl-fifastra.com/assets/images/rank/" +
                this.planetItems[index].srcRank;
              //console.log(index);
              ctx.drawImage(
                imageBawah,
                x - chart.width / 40,
                yAxis.bottom,
                chart.width / 20,
                chart.width / 20
              );
            });
          },
        },
      ],
    };
  },
  methods: {
    async getPlanet() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getplanet",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            this.planetItems = data.data;
          }
          console.log(data.message);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    },
    async getDataAwal() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getdataawal",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            //this.planetHereStyle = data.planetHereStyle;
            //this.textNama = data.textNama;
            this.userProgres = data.data.userProgres;
            //this.planetItems = data.data;
          }
          console.log(data.message);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    enterMainView() {
      this.classSplashLogo = "splash-logo-main scale-up-center-r";
      this.splashItems[0].class = "splash-cloud-left slide-left-r";
      this.splashItems[1].class = "splash-cloud-right slide-right-r";
      setTimeout(() => {
        this.splashItems[0].class = "splash-cloud-left slide-left";
        this.splashItems[1].class = "splash-cloud-right slide-right";
        this.showWelcomeScreen = false;
      }, 500);
    },
    enterDashboard() {
      this.showWelcomeText = false;
      this.showTypeWriter = true;
      this.typeWriterAtas();
      setTimeout(() => {
        this.typeWriterBawah();
      }, 2800);
      /*
      const interval = setInterval(() => {
        if (this.$refs.typeWriter) {
          this.typeWriter();
          clearInterval(interval);
        }
      }, 50);
      */
    },
    async showPlanetPopUp(idPlanet) {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getrincianplanet",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
              idplanet: idPlanet,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            console.log("Berhasil mengambil data");
            this.planetPopUpHeader = data.data.bulan;
            this.planetPopupClass = data.data.planetclass;
            this.planetPopUpStyle = data.data.imgstyle;
            this.planetPopUpSrc = data.data.imgsrc;
            this.planetPopUpBadge = data.data.badgesrc;
            this.planetPopUpTarget = data.data.target;
            this.planetPopUpCapaian = data.data.capaian;
            this.planetPopUpPersen = data.data.persen;
            this.isPlanetPopUp = true;
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    hidePlanetPopUp() {
      this.isPlanetPopUp = false;
    },
    hideLeftMenu() {
      this.leftButtonMenu = "left-button menu-out";
      this.leftButtonArrow = "hide-button menu-in";
    },
    showLeftMenu() {
      this.leftButtonMenu = "left-button menu-in";
      this.leftButtonArrow = "hide-button menu-out";
    },
    showQuest() {
      this.showQuestModal = true;
    },
    hideQuest() {
      this.showQuestModal = false;
    },
    showPusatInformasi() {
      this.isPusatInformasi = true;
    },
    hidePusatInformasi() {
      this.isPusatInformasi = false;
    },
    showPromoModal() {
      this.isPromoModal = true;
    },
    hidePromoModal() {
      this.isPromoModal = false;
    },
    async showCreditScore() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getmonthly",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            console.log("Berhasil mengambil data");
            // FUNGSI BUAT KALENDER
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();

            const daysInMonth = new Date(
              currentYear,
              currentMonth + 1,
              0
            ).getDate();
            const firstDayOfMonth = new Date(
              currentYear,
              currentMonth,
              1
            ).getDay();

            let dayCount = 1;
            let aktif = 0;
            const calendar = [];

            for (let i = 0; i < 6; i++) {
              const week = [];
              for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDayOfMonth - 1) {
                  // Empty cells before the first day of the month
                  week.push(null);
                  //week.push("badges.png");
                } else if (dayCount <= daysInMonth) {
                  //week.push(dayCount);
                  week.push(data.data[dayCount].imgsrc);
                  if (data.data[dayCount].imgsrc == "bintang_active.png") {
                    aktif++;
                  }
                  dayCount++;
                } else {
                  // Empty cells after the last day of the month
                  week.push(null);
                  //week.push("badges.png");
                }
              }
              //calendar.push(week);
              if (week.some((day) => day !== null)) {
                calendar.push(week);
              }
            }
            this.creditMonthly = calendar;
            this.creditMonthlyAktif = aktif;
            this.totalDayMonth = dayCount;
            this.isCreditScore = true;
            const refBulan = {
              0: "Januari",
              1: "Februari",
              2: "Maret",
              3: "April",
              4: "Mei",
              5: "Juni",
              6: "Juli",
              7: "Agustus",
              8: "September",
              9: "Oktober",
              10: "November",
              11: "Desember",
            };
            this.bulanCreditScore = refBulan[currentMonth] + " " + currentYear;
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    hideCreditScore() {
      this.isCreditScore = false;
    },
    cekBadge(badge) {
      const listBadge = {
        Silver: "chalenger.png",
        Gold: "grandmaster.png",
        Bronze: "master.png",
      };
      return listBadge[badge];
    },
    async getDataChart() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getcapaian",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          //if (data.code == "200") {
          console.log("Berhasil mengambil data");
          this.chartLabel.datasets[0].data = data.data?.persen ?? 0;
          this.chartLabel.datasets[1].data = data.data?.capaian ?? 0;
          this.chartLabel.datasets[2].data = data.data?.target ?? 0;
          this.chartLabel.datasets[1].data.forEach((data) => {
            try {
              this.totalActual += parseInt(data);
            } catch (err) {
              console.error(err);
            }
          });
          this.chartLabel.datasets[2].data.forEach((data) => {
            try {
              this.totalTarget += parseInt(data);
            } catch (err) {
              console.error(err);
            }
          });
          this.totalProsen = (
            (this.totalActual / this.totalTarget) *
            100
          ).toFixed(1);
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    async showChart() {
      await this.getDataChart();
      this.showChartModal = true;
    },
    hideChart() {
      this.showChartModal = false;
    },
    showTutorial() {
      this.showTutorialModal = true;
    },
    hideTutorial() {
      this.showTutorialModal = false;
    },
    showGimmick() {
      (this.isGimmick = true), (this.isPusatInformasi = false);
      this.isTutorialOne = false;
      this.isTutorialTwo = false;
      this.showAudio = false;
    },
    showTutorialOne() {
      this.isPusatInformasi = false;
      this.isTutorialOne = true;
      this.isTutorialTwo = false;
      this.showAudio = false;
    },
    showTutorialTwo() {
      this.isPusatInformasi = false;
      this.isTutorialOne = false;
      this.isTutorialTwo = true;
      this.showAudio = false;
    },
    hidePromosiAwal() {
      this.isPromosiAwal = false;
      this.isTutorialOne = true;
      this.isTutorialTwo = false;
    },
    hideGimmick() {
      this.isGimmick = false;
      this.isTutorialOne = true;
      this.isTutorialTwo = false;
      //this.showAudio = true;
    },
    hideTutorialOne() {
      this.isPusatInformasi = false;
      this.isTutorialOne = false;
      this.isTutorialTwo = this.isNextTutorialTwo;
      this.showAudio = !this.isNextTutorialTwo;
      this.isNextTutorialTwo = false;
    },
    hideTutorialTwo() {
      this.isPusatInformasi = false;
      this.isTutorialOne = false;
      this.isTutorialTwo = false;
      this.showAudio = !this.isNextTutorialTwo;
    },
    showComingSoon() {
      this.isComingSoon = true;
    },
    hideComingSoon() {
      this.isComingSoon = false;
    },
    showGames() {
      if (this.userGames == "SPEED") {
        this.isSpeedGames = true;
        this.showAudio = false;
      } else if (this.userGames == "HUNTER") {
        this.isHunterGames = true;
        this.showAudio = false;
      } else if (this.userGames == "RUNNER") {
        this.isRunnerGames = true;
        this.showAudio = false;
      }
    },
    hideGames() {
      if (this.userGames == "SPEED") {
        this.isSpeedGames = false;
        this.showAudio = true;
      } else if (this.userGames == "HUNTER") {
        this.isHunterGames = false;
        this.showAudio = true;
      } else if (this.userGames == "RUNNER") {
        this.isRunnerGames = false;
        this.showAudio = true;
      }
    },
    handleItemClick(item, index) {
      // Your click handling logic here
      console.log(`Clicked on ${item} at index ${index}`);
    },
    typeWriterAtas() {
      const yy = "SELAMAT DATANG";
      if (this.textAtasPos < yy.length) {
        var ketik = yy.charAt(this.textAtasPos);
        try {
          this.textWriterAtas += ketik;
        } catch {
          console.log("error animation typeWiter");
        }
        this.textAtasPos++;
        setTimeout(this.typeWriterAtas, this.writeSpeed);
      }
    },
    typeWriterBawah() {
      let zz = "DEALER " + this.textNama;
      if (this.textBawahPos < zz.length) {
        var ketik = zz.charAt(this.textBawahPos);
        try {
          this.textWriterBawah += ketik;
        } catch {
          console.log("error animation typeWiter");
        }
        this.textBawahPos++;
        setTimeout(this.typeWriterBawah, this.writeSpeed);
      }
    },
    resizeWindow() {
      this.isPortrait = window.innerWidth < 576;
      //this.isPortrait = false;
    },
    formatNumber(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    },
    setPromosiAwal() {
      this.isPromosiAwal = true;
      this.isTutorialOne = false;
    },
    setGimmickVideo() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();
      const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
      //console.log(currentDate);
      //console.log(currentYear);
      //console.log(currentMonth);
      //console.log(currentDay);
      // VIDEO TIAP TANGGAL 1
      if (currentDay == 1) {
        this.videoGimmick = this.getGimmickOpening(currentMonth);
        /*
        if (this.videoGimmick == "") {
          this.isGimmick = false;
          this.isTutorialOne = true;
        } else {
          this.isGimmick = true;
          this.isTutorialOne = false;
        }
        */
      }
      // VIDEO TENGAH BULAN
      else if (currentDay == 15) {
        this.videoGimmick = this.getGimmickHalf(currentMonth);
        //this.isGimmick = true;
        //this.isTutorialOne = false;
      }
      // VIDEO AKHIR BULAN
      else if (currentDay == daysInMonth) {
        this.videoGimmick = this.getGimmickClosing();
        //this.isGimmick = true;
        //this.isTutorialOne = false;
      }
    },
    getGimmickOpening(bulan) {
      const listVideo = {
        SPEED: {
          4: "speed-april.mp4",
          5: "speed-mei.mp4",
          6: "speed-juni.mp4",
          7: "speed-juli.mp4",
          8: "speed-agustus.mp4",
        },
        HUNTER: {
          4: "hunter-april.mp4",
          5: "hunter-mei.mp4",
          6: "hunter-juni.mp4",
          7: "hunter-juli.mp4",
          8: "hunter-agustus.mp4",
        },
        RUNNER: {
          4: "runner-april.mp4",
          5: "runner-mei.mp4",
          6: "runner-juni.mp4",
          7: "runner-juli.mp4",
          8: "runner-agustus.mp4",
        },
      };
      if (
        Object.prototype.hasOwnProperty.call(listVideo[this.userGames], bulan)
      ) {
        return listVideo[this.userGames][bulan];
      } else {
        /*
        if (this.userGames == "SPEED") {
          return "defaultspeed.mp4";
        } else if (this.userGames == "HUNTER") {
          return "";
        } else if (this.userGames == "RUNNER") {
          return "";
        }
        */
        return "";
      }
    },
    getGimmickHalf(bulan) {
      const listVideo = {
        SPEED: "speed-half.mp4",
        HUNTER: "hunter-half.mp4",
        RUNNER: "runner-half.mp4",
      };
      const listVideoMaret = {
        SPEED: "speed-half-maret.mp4",
        HUNTER: "hunter-half-maret.mp4",
        RUNNER: "runner-half-maret.mp4",
      };
      if (bulan == 3) {
        return listVideoMaret[this.userGames];
      } else {
        return listVideo[this.userGames];
      }
    },
    getGimmickClosing() {
      const listVideo = {
        SPEED: "speed-closing.mp4",
        HUNTER: "hunter-closing.mp4",
        RUNNER: "runner-closing.mp4",
      };
      return listVideo[this.userGames];
    },
  },
  async created() {
    window.addEventListener("resize", this.resizeWindow);

    this.setGimmickVideo();
    this.setPromosiAwal();
    console.log(this.videoGimmick);
    if (this.userGames == "SPEED") {
      this.srcSplashLogo = "speed/logo.png";
      this.srcSplashWelcome = "speed/welcome.png";
      this.classIsland = "island island-speed";
      this.srcBacksound = "speed-music.mp3";
      this.videoTutorialOne = "talentspeed.mp4";
      this.videoTutorialTwo = "tutorialspeed.mp4";
      this.$store.commit("updateAppClass", "speed-style");
    } else if (this.userGames == "HUNTER") {
      this.srcSplashLogo = "hunter/logo.png";
      this.srcSplashWelcome = "hunter/welcome.png";
      this.classIsland = "island island-hunter";
      this.srcBacksound = "hunter-music.mp3";
      this.videoTutorialOne = "talenthunter.mp4";
      this.videoTutorialTwo = "tutorialhunter.mp4";
      this.$store.commit("updateAppClass", "hunter-style");
    } else if (this.userGames == "RUNNER") {
      this.srcSplashLogo = "runner/logo.png";
      this.srcSplashWelcome = "runner/welcome.png";
      this.classIsland = "island island-runner";
      this.srcBacksound = "runner-music.mp3";
      this.videoTutorialOne = "talentrunner.mp4";
      this.videoTutorialTwo = "tutorialrunner.mp4";
      this.$store.commit("updateAppClass", "runner-style");
    }

    try {
      // Menggunakan await untuk menunggu hingga pengambilan data selesai
      await this.getPlanet();
      await this.getDataAwal();
      await this.getDataChart();
      // this.showChartModal = true;
    } catch (error) {
      console.error("Inisialisasi data awal gagal -- ", error);
    }
  },
  unmount() {
    window.removeEventListener("resize", this.resizeWindow);
  },
  mounted() {
    document.title = "FUNTASTIC LEAGUE - DASHBOARD";
    const isAuthenticated = this.$store.getters.isAuthenticated;
    //const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (!isAuthenticated) {
      // Jika belum login, redirect ke awal
      this.$router.push("/");
    }
    this.classSplashLogo = "splash-logo-main scale-up-center";
    this.splashItems[0].class = "splash-cloud-left slide-left";
    this.splashItems[1].class = "splash-cloud-right slide-right";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../assets/css/main.css";
</style>
