import { createApp } from "vue";
//import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueChartkick from "vue-chartkick";
import Chart from "chart.js/auto";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueChartkick.use(Chart));
app.mount("#app");
/*createApp(App).use(router).use(store).use(Chart).use(registerables).mount('#app');*/
