<!-- components/SpeedGames.vue -->
<!-- penambahan animasi listrik -->
<template>
  <div class="speedgames-top">
    <div class="speedgames-top-left">TIME : {{ this.counterTimerText }}</div>
    <div class="speedgames-top-mid">
      <img src="../assets/images/speed/logo.png" />
    </div>
    <div class="speedgames-top-right">
      SCORE : {{ this.formatNumber(score) }}
    </div>
  </div>
  <div class="speedgames-frame">
    <div class="speedgames-container">
      <canvas id="speedcanvas"></canvas>
      <div class="speedgames-combotext" :style="comboStyle">
        <span v-html="scoreComboText"></span>
      </div>
    </div>
  </div>
  <div v-if="gameover" class="speedgames-text">
    GAME OVER !<br />
    Selamat Skor Anda : {{ this.formatNumber(score) }}
    <div @click="newGame" class="speedgames-button">
      <img src="../assets/images/button_next.png" />
    </div>
  </div>

  <div class="speedgames-leaderboard" @click="showLeaderboard">
    <img src="../assets/images/speed/icon_leaderboard.png" />
  </div>
  <Transition>
    <div
      v-if="isLeaderboard"
      @click.self="hideLeaderboard"
      class="full-screen modal-v modal-leaderboard"
    >
      <div class="logo-top">
        <img src="../assets/images/speed/logo.png" />
      </div>
      <div class="close-modal" @click="hideLeaderboard">
        <img src="../assets/images/close_icon.png" />
      </div>
      <div class="leaderboard-frame">
        <div class="leaderboard-header">LEADERBOARD</div>
        <div class="leaderboard-container">
          <div
            v-for="(leaderboard, index) in leaderboardData"
            :key="index"
            :class="leaderboard.class"
          >
            {{ leaderboard.nama }}
            <span class="leaderboard-score">
              {{ this.formatNumber(leaderboard.score) }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  data() {
    return {
      canvas: null,
      context: null,
      frameID: null,
      isRunning: true,
      lastframe: 0,
      fpstime: 0,
      framecount: 0,
      fps: 0,
      drag: false,
      level: {
        x: 0, //250, // X position
        y: 0, //113, // Y position
        columns: 12, // Number of tile columns
        rows: 5, // Number of tile rows
        tilewidth: 80, //window.innerWidth / 18, // Visual width of a tile
        tileheight: 80, //window.innerWidth / 18, // Visual height of a tile
        tiles: [], // The two-dimensional tile array
        selectedtile: { selected: false, column: 0, row: 0 },
      },
      tileimages: ["bulat.png", "segitiga.png", "kotak.png", "x.png"],
      clusters: [],
      moves: [],
      currentmove: { column1: 0, row1: 0, column2: 0, row2: 0 },
      gamestates: { init: 0, ready: 1, resolve: 2 },
      gamestate: 0,
      score: 0,
      scoreCombo: 0,
      scoreComboText: "",
      comboStyle: "",
      lineCoba: 0,
      isPause: false,
      savedscore: false,
      animationstate: 0,
      animationtime: 0,
      animationtimetotal: 0.3,
      showmoves: false,
      gameover: false,
      counterTimerAwal: 120,
      counterTimer: this.counterTimerAwal,
      counterInterval: null,
      counterTimerText: "02:00",
      coinSound: new Audio(),
      bgmSound: new Audio(),
      isLeaderboard: false,
      leaderboardData: [
        { class: "leaderboard-pos1", nama: "1. coba 1", score: 12000 },
        { class: "leaderboard-pos2", nama: "2. coba 2", score: 2100 },
        { class: "leaderboard-pos3", nama: "3. coba 3", score: 100 },
        { class: "leaderboard-pos4", nama: "4. coba 4", score: 100 },
        { class: "leaderboard-pos5", nama: "5. coba 5", score: 100 },
        { class: "leaderboard-pos6", nama: "6. coba 6", score: 100 },
        { class: "leaderboard-pos7", nama: "7. coba 7", score: 100 },
        { class: "leaderboard-pos8", nama: "8. coba 8", score: 100 },
        { class: "leaderboard-pos9", nama: "9. coba 9", score: 100 },
        { class: "leaderboard-pos10", nama: "10. coba 10", score: 100 },
        { class: "leaderboard-me", nama: "skor saya : ", score: 100 },
      ],
    };
  },
  methods: {
    countDownTimer() {
      var minutes, seconds;
      if (this.counterTimer < 0) {
        this.counterTimerText = "00:00";
        if (this.gamestate == this.gamestates.ready) {
          this.gameover = true;
          this.simpanGames();
          clearInterval(this.counterInterval);
          this.counterInterval == null;
        }
      } else {
        minutes = parseInt(this.counterTimer / 60, 10);
        seconds = parseInt(this.counterTimer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.counterTimerText = minutes + ":" + seconds;
        this.counterTimer--;
      }
    },
    init() {
      this.canvas.addEventListener("mousemove", this.onMouseMove);
      this.canvas.addEventListener("mousedown", this.onMouseDown);
      this.canvas.addEventListener("mouseup", this.onMouseUp);
      this.canvas.addEventListener("mouseout", this.onMouseOut);
      for (var i = 0; i < this.level.columns; i++) {
        this.level.tiles[i] = [];
        for (var j = 0; j < this.level.rows; j++) {
          // Definisi type tile dan shif untuk animasi
          this.level.tiles[i][j] = { type: 0, shift: 0 };
        }
      }

      // New game
      this.newGame();

      // Enter main loop
      //this.main(0);
      this.isRunning = true;
      this.frameID = setInterval(this.main, 50);
    },
    main() {
      if (this.isRunning) {
        this.update();
        this.render();
      }
    },
    update() {
      if (this.gamestate == this.gamestates.ready) {
        // Game is ready for player input
        // Check for game over
        if (this.moves.length <= 0) {
          this.gameover = true;
        }
      } else if (this.gamestate == this.gamestates.resolve) {
        // Game is busy resolving and animating clusters
        //this.animationtime += dt;
        this.animationtime += 0.05;

        if (this.animationstate == 0) {
          // Find clusters
          this.findClusters();

          if (this.clusters.length > 0) {
            // Add points to the score
            var tambahanpoin;

            for (var i = 0; i < this.clusters.length; i++) {
              // Add extra points for longer clusters
              tambahanpoin =
                100 * (this.clusters[i].length - 2) + 100 * this.scoreCombo;
              this.score += tambahanpoin;
            }
            this.scoreCombo++;

            if (this.scoreCombo > 1) {
              this.scoreComboText =
                "COMBO x" +
                this.scoreCombo +
                "<br/>" +
                "Bonus poin + " +
                100 * this.scoreCombo;

              this.isRunning = false;
              this.animateCombo();
            }

            // Clusters found, remove them
            this.removeClusters();

            // Tiles need to be shifted
            this.animationstate = 1;
          } else {
            // No clusters found, animation complete
            this.gamestate = this.gamestates.ready;
            this.scoreCombo = 0;
          }
        } else if (this.animationstate == 1) {
          // Tiles need to be shifted
          if (this.animationtime > this.animationtimetotal) {
            // Shift tiles
            this.shiftTiles();

            // New clusters need to be found
            this.animationstate = 0;
            this.animationtime = 0;

            // Check if there are new clusters
            this.findClusters();
            if (this.clusters.length <= 0) {
              // Animation complete
              this.gamestate = this.gamestates.ready;
              this.scoreCombo = 0;
            }
          }
        } else if (this.animationstate == 2) {
          // Swapping tiles animation
          if (this.animationtime > this.animationtimetotal) {
            // Swap the tiles
            this.swap(
              this.currentmove.column1,
              this.currentmove.row1,
              this.currentmove.column2,
              this.currentmove.row2
            );

            // Check if the swap made a cluster
            this.findClusters();
            if (this.clusters.length > 0) {
              // Valid swap, found one or more clusters
              // Prepare animation states
              this.animationstate = 0;
              this.animationtime = 0;
              this.gamestate = this.gamestates.resolve;
            } else {
              // Invalid swap, Rewind swapping animation
              this.animationstate = 3;
              this.animationtime = 0;
            }

            // Update moves and clusters
            this.findMoves();
            this.findClusters();
          }
        } else if (this.animationstate == 3) {
          // Rewind swapping animation
          if (this.animationtime > this.animationtimetotal) {
            // Invalid swap, swap back
            this.swap(
              this.currentmove.column1,
              this.currentmove.row1,
              this.currentmove.column2,
              this.currentmove.row2
            );

            // Animation complete
            this.gamestate = this.gamestates.ready;
          }
        }

        // Update moves and clusters
        this.findMoves();
        this.findClusters();
      }
    },
    updateFps(dt) {
      if (this.fpstime > 0.25) {
        // Calculate fps
        this.fps = Math.round(this.framecount / this.fpstime);

        // Reset time and framecount
        this.fpstime = 0;
        this.framecount = 0;
      }
      //console.log(this.framecount + " -- " + this.fpstime);
      // Increase time and framecount
      this.fpstime += dt;
      this.framecount++;
    },
    drawCenterText(text, x, y, width) {
      var textdim = this.context.measureText(text);
      this.context.fillText(text, x + (width - textdim.width) / 2, y);
    },
    render() {
      // Draw the frame
      this.drawFrame();

      // Draw background tile
      var levelwidth = this.level.columns * this.level.tilewidth;
      var levelheight = this.level.rows * this.level.tileheight;
      this.context.clearRect(0, 30, levelwidth, levelheight);

      // Render tiles
      this.renderTiles();

      // Render clusters
      this.renderClusters();

      // Render moves, ketika sudah tidak ada cluster
      if (
        this.showmoves &&
        this.clusters.length <= 0 &&
        this.gamestate == this.gamestates.ready
      ) {
        this.renderMoves();
      }

      // Game Over overlay
      if (this.gameover) {
        this.context.fillStyle = "rgba(0, 0, 0, 0.8)";
        this.context.fillRect(
          this.level.x,
          this.level.y,
          levelwidth,
          levelheight
        );
        clearInterval(this.counterInterval);
        this.counterInterval == true;
      }
    },
    drawFrame() {
      // Clear background dan border
      this.context.clearRect(
        1,
        1,
        this.canvas.width - 2,
        this.canvas.height - 2
      );
    },
    renderTiles() {
      for (var i = 0; i < this.level.columns; i++) {
        for (var j = 0; j < this.level.rows; j++) {
          // ambil shift untuk animasi
          var shift = this.level.tiles[i][j].shift;

          // hitung koordinat tile
          var coord = this.getTileCoordinate(
            i,
            j,
            0,
            (this.animationtime / this.animationtimetotal) * shift
          );
          // ambil type tile
          var col = this.tileimages[this.level.tiles[i][j].type];

          // cek apakah ada tile
          if (this.level.tiles[i][j].type >= 0) {
            // Draw tile sesuai definisi
            this.drawImage(coord.tilex, coord.tiley, col);
          }

          // Draw the selected tile
          if (this.level.selectedtile.selected) {
            if (
              this.level.selectedtile.column == i &&
              this.level.selectedtile.row == j
            ) {
              // buat gambar tile
              this.context.clearRect(
                coord.tilex,
                coord.tiley,
                this.level.tilewidth,
                this.level.tileheight
              );
              this.drawImageSelected(coord.tilex, coord.tiley, col);
            }
          }
        }
      }

      // Render the swap animation
      if (
        this.gamestate == this.gamestates.resolve &&
        (this.animationstate == 2 || this.animationstate == 3)
      ) {
        // Calculate the x and y shift
        var shiftx = this.currentmove.column2 - this.currentmove.column1;
        var shifty = this.currentmove.row2 - this.currentmove.row1;

        // First tile
        var coord1 = this.getTileCoordinate(
          this.currentmove.column1,
          this.currentmove.row1,
          0,
          0
        );
        var coord1shift = this.getTileCoordinate(
          this.currentmove.column1,
          this.currentmove.row1,
          (this.animationtime / this.animationtimetotal) * shiftx,
          (this.animationtime / this.animationtimetotal) * shifty
        );
        //var col1 = tilecolors[level.tiles[currentmove.column1][currentmove.row1].type];
        var col1 =
          this.tileimages[
            this.level.tiles[this.currentmove.column1][this.currentmove.row1]
              .type
          ];

        // Second tile
        var coord2 = this.getTileCoordinate(
          this.currentmove.column2,
          this.currentmove.row2,
          0,
          0
        );
        var coord2shift = this.getTileCoordinate(
          this.currentmove.column2,
          this.currentmove.row2,
          (this.animationtime / this.animationtimetotal) * -shiftx,
          (this.animationtime / this.animationtimetotal) * -shifty
        );
        //var col2 = tilecolors[level.tiles[currentmove.column2][currentmove.row2].type];
        var col2 =
          this.tileimages[
            this.level.tiles[this.currentmove.column2][this.currentmove.row2]
              .type
          ];

        // Draw bakcground hitam saat swap
        this.drawTile(coord1.tilex, coord1.tiley, 0, 0, 0);
        this.drawTile(coord2.tilex, coord2.tiley, 0, 0, 0);
        // Clear background saat swap
        //this.context.clearRect(coord1.tilex, coord1.tiley, this.tilewidth, this.tileheight);
        //this.context.clearRect(coord2.tilex, coord2.tiley, this.tilewidth, this.tileheight);

        // Change the order, depending on the animation state
        if (this.animationstate == 2) {
          // Draw the tiles
          //drawTile(coord1shift.tilex, coord1shift.tiley, col1[0], col1[1], col1[2]);
          //drawTile(coord2shift.tilex, coord2shift.tiley, col2[0], col2[1], col2[2]);
          this.drawImage(coord1shift.tilex, coord1shift.tiley, col1);
          this.drawImage(coord2shift.tilex, coord2shift.tiley, col2);
        } else {
          // Draw the tiles
          //drawTile(coord2shift.tilex, coord2shift.tiley, col2[0], col2[1], col2[2]);
          //drawTile(coord1shift.tilex, coord1shift.tiley, col1[0], col1[1], col1[2]);
          this.drawImage(coord2shift.tilex, coord2shift.tiley, col2);
          this.drawImage(coord1shift.tilex, coord1shift.tiley, col1);
        }
      }
    },
    getTileCoordinate(column, row, columnoffset, rowoffset) {
      var tilex = this.level.x + (column + columnoffset) * this.level.tilewidth;
      var tiley = this.level.y + (row + rowoffset) * this.level.tileheight;
      return { tilex: tilex, tiley: tiley };
    },
    drawTile(x, y, r, g, b) {
      this.context.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
      //this.context.fillStyle = "#0000";
      /*
      this.context.fillRect(
        x + 2,
        y + 2,
        this.level.tilewidth - 4,
        this.level.tileheight - 4
      );
      */
      this.context.clearRect(x, y, this.level.tilewidth, this.level.tileheight);
    },
    drawImage(x, y, src) {
      var imgTile = new Image();
      // kalau pakai require memori / ram leaked
      //imgTile.src = require("../assets/images/speed/" + src);
      imgTile.src = "https://admin.fl-fifastra.com/assets/images/speed/" + src;
      this.context.drawImage(
        imgTile,
        x + 2,
        y + 2,
        this.level.tilewidth - 4,
        this.level.tileheight - 4
      );
    },
    drawImageSelected(x, y, src) {
      var imgTile = new Image();
      imgTile.src = require("../assets/images/speed/" + src);
      this.context.drawImage(
        imgTile,
        x + 8,
        y + 8,
        this.level.tilewidth - 16,
        this.level.tileheight - 16
      );
    },
    renderClusters() {
      for (var i = 0; i < this.clusters.length; i++) {
        // Calculate the tile coordinates
        var coord = this.getTileCoordinate(
          this.clusters[i].column,
          this.clusters[i].row,
          0,
          0
        );

        try {
            this.coinSound.currentTime = 0;
            this.coinSound.play().catch(() => {});
        } catch (err) {
          console.log("error audio");
        }
        this.isRunning = false;
        this.animateLine(coord, i);
      }
    },
    renderMoves() {
      for (var i = 0; i < this.moves.length; i++) {
        // Calculate coordinates of tile 1 and 2
        var coord1 = this.getTileCoordinate(
          this.moves[i].column1,
          this.moves[i].row1,
          0,
          0
        );
        var coord2 = this.getTileCoordinate(
          this.moves[i].column2,
          this.moves[i].row2,
          0,
          0
        );
        // Draw a line from tile 1 to tile 2
        this.context.strokeStyle = "#ff0000";
        this.context.beginPath();
        this.context.moveTo(
          coord1.tilex + this.level.tilewidth / 2,
          coord1.tiley + this.level.tileheight / 2
        );
        this.context.lineTo(
          coord2.tilex + this.level.tilewidth / 2,
          coord2.tiley + this.level.tileheight / 2
        );
        this.context.stroke();
      }
    },
    newGame() {
      this.context.clearRect(
        0,
        0,
        this.context.canvas.width,
        this.context.canvas.height
      );
      // Reset score
      this.score = 0;
      this.scoreCombo = 0;
      this.lastframe = 0;
      this.savedscore = false;

      // Set the gamestate to ready
      this.gamestate = this.gamestates.ready;

      // Reset game over
      this.gameover = false;

      // Create the level
      this.createLevel();

      // Find initial clusters and moves
      this.findMoves();
      this.findClusters();

      this.counterTimer = this.counterTimerAwal;
      this.counterInterval = setInterval(this.countDownTimer, 1000);
    },
    createLevel() {
      var done = false;

      // Keep generating levels until it is correct
      while (!done) {
        // Create a level with random tiles
        for (var i = 0; i < this.level.columns; i++) {
          for (var j = 0; j < this.level.rows; j++) {
            this.level.tiles[i][j].type = this.getRandomTile();
          }
        }

        // Resolve the clusters
        this.resolveClusters();

        // Check if there are valid moves
        this.findMoves();

        // Done when there is a valid move
        if (this.moves.length > 0) {
          done = true;
        }
      }
    },
    getRandomTile() {
      //return Math.floor(Math.random() * tilecolors.length);
      return Math.floor(Math.random() * this.tileimages.length);
    },
    resolveClusters() {
      // Check for clusters
      this.findClusters();

      // While there are clusters left
      while (this.clusters.length > 0) {
        // Remove clusters
        this.removeClusters();

        // Shift tiles
        this.shiftTiles();

        // Check if there are clusters left
        this.findClusters();
      }
    },
    findClusters() {
      // Reset clusters
      this.clusters = [];

      // Find horizontal clusters
      for (var j = 0; j < this.level.rows; j++) {
        // Start with a single tile, cluster of 1
        var matchlength = 1;
        for (var i = 0; i < this.level.columns; i++) {
          var checkcluster = false;

          if (i == this.level.columns - 1) {
            // Last tile
            checkcluster = true;
          } else {
            // Check the type of the next tile
            if (
              this.level.tiles[i][j].type == this.level.tiles[i + 1][j].type &&
              this.level.tiles[i][j].type != -1
            ) {
              // Same type as the previous tile, increase matchlength
              matchlength += 1;
            } else {
              // Different type
              checkcluster = true;
            }
          }

          // Check if there was a cluster
          if (checkcluster) {
            if (matchlength >= 3) {
              // Found a horizontal cluster
              this.clusters.push({
                column: i + 1 - matchlength,
                row: j,
                length: matchlength,
                horizontal: true,
              });
            }

            matchlength = 1;
          }
        }
      }

      // Find vertical clusters
      for (i = 0; i < this.level.columns; i++) {
        // Start with a single tile, cluster of 1
        matchlength = 1;
        for (j = 0; j < this.level.rows; j++) {
          checkcluster = false;

          if (j == this.level.rows - 1) {
            // Last tile
            checkcluster = true;
          } else {
            // Check the type of the next tile
            if (
              this.level.tiles[i][j].type == this.level.tiles[i][j + 1].type &&
              this.level.tiles[i][j].type != -1
            ) {
              // Same type as the previous tile, increase matchlength
              matchlength += 1;
            } else {
              // Different type
              checkcluster = true;
            }
          }

          // Check if there was a cluster
          if (checkcluster) {
            if (matchlength >= 3) {
              // Found a vertical cluster
              this.clusters.push({
                column: i,
                row: j + 1 - matchlength,
                length: matchlength,
                horizontal: false,
              });
            }

            matchlength = 1;
          }
        }
      }
    },
    findMoves() {
      // Reset moves
      this.moves = [];

      // Check horizontal swaps
      for (var j = 0; j < this.level.rows; j++) {
        for (var i = 0; i < this.level.columns - 1; i++) {
          // Swap, find clusters and swap back
          this.swap(i, j, i + 1, j);
          this.findClusters();
          this.swap(i, j, i + 1, j);

          // Check if the swap made a cluster
          if (this.clusters.length > 0) {
            // Found a move
            this.moves.push({ column1: i, row1: j, column2: i + 1, row2: j });
          }
        }
      }

      // Check vertical swaps
      for (i = 0; i < this.level.columns; i++) {
        for (j = 0; j < this.level.rows - 1; j++) {
          // Swap, find clusters and swap back
          this.swap(i, j, i, j + 1);
          this.findClusters();
          this.swap(i, j, i, j + 1);

          // Check if the swap made a cluster
          if (this.clusters.length > 0) {
            // Found a move
            this.moves.push({ column1: i, row1: j, column2: i, row2: j + 1 });
          }
        }
      }
      // Reset clusters
      this.clusters = [];
    },
    loopClusters(func) {
      for (var i = 0; i < this.clusters.length; i++) {
        //  { column, row, length, horizontal }
        var cluster = this.clusters[i];
        var coffset = 0;
        var roffset = 0;
        for (var j = 0; j < cluster.length; j++) {
          func(i, cluster.column + coffset, cluster.row + roffset, cluster);

          if (cluster.horizontal) {
            coffset++;
          } else {
            roffset++;
          }
        }
      }
    },
    removeClusters() {
      // Change the type of the tiles to -1, indicating a removed tile
      //loopClusters(function(index, column, row, cluster) { level.tiles[column][row].type = -1; });
      this.loopClusters((index, column, row) => {
        this.level.tiles[column][row].type = -1;
      });

      // Calculate how much a tile should be shifted downwards
      for (var i = 0; i < this.level.columns; i++) {
        var shift = 0;
        for (var j = this.level.rows - 1; j >= 0; j--) {
          // Loop from bottom to top
          if (this.level.tiles[i][j].type == -1) {
            // Tile is removed, increase shift
            shift++;
            this.level.tiles[i][j].shift = 0;
          } else {
            // Set the shift
            this.level.tiles[i][j].shift = shift;
          }
        }
      }
    },
    shiftTiles() {
      // Shift tiles
      for (var i = 0; i < this.level.columns; i++) {
        for (var j = this.level.rows - 1; j >= 0; j--) {
          // Loop from bottom to top
          if (this.level.tiles[i][j].type == -1) {
            // Insert new random tile
            this.level.tiles[i][j].type = this.getRandomTile();
          } else {
            // Swap tile to shift it
            var shift = this.level.tiles[i][j].shift;
            if (shift > 0) {
              this.swap(i, j, i, j + shift);
            }
          }

          // Reset shift
          this.level.tiles[i][j].shift = 0;
        }
      }
    },
    getMouseTile(pos) {
      // Calculate the index of the tile
      var tx = Math.floor((pos.x - this.level.x) / this.level.tilewidth);
      var ty = Math.floor((pos.y - this.level.y) / this.level.tileheight);

      // Check if the tile is valid
      if (
        tx >= 0 &&
        tx < this.level.columns &&
        ty >= 0 &&
        ty < this.level.rows
      ) {
        // Tile is valid
        return {
          valid: true,
          x: tx,
          y: ty,
        };
      }

      // No valid tile
      return {
        valid: false,
        x: 0,
        y: 0,
      };
    },
    canSwap(x1, y1, x2, y2) {
      // Check if the tile is a direct neighbor of the selected tile
      if (
        (Math.abs(x1 - x2) == 1 && y1 == y2) ||
        (Math.abs(y1 - y2) == 1 && x1 == x2)
      ) {
        return true;
      }

      return false;
    },
    swap(x1, y1, x2, y2) {
      var typeswap = this.level.tiles[x1][y1].type;
      this.level.tiles[x1][y1].type = this.level.tiles[x2][y2].type;
      this.level.tiles[x2][y2].type = typeswap;
    },
    mouseSwap(c1, r1, c2, r2) {
      // Save the current move
      this.currentmove = { column1: c1, row1: r1, column2: c2, row2: r2 };

      // Deselect
      this.level.selectedtile.selected = false;

      // Start animation
      this.animationstate = 2;
      this.animationtime = 0;
      this.gamestate = this.gamestates.resolve;
    },
    onMouseMove(e) {
      // Get the mouse position
      var pos = this.getMousePos(this.canvas, e);

      // Check if we are dragging with a tile selected
      if (this.drag && this.level.selectedtile.selected) {
        // Get the tile under the mouse
        var mt = this.getMouseTile(pos);
        if (mt.valid) {
          // Valid tile

          // Check if the tiles can be swapped
          if (
            this.canSwap(
              mt.x,
              mt.y,
              this.level.selectedtile.column,
              this.level.selectedtile.row
            )
          ) {
            // Swap the tiles
            this.mouseSwap(
              mt.x,
              mt.y,
              this.level.selectedtile.column,
              this.level.selectedtile.row
            );
          }
        }
      }
    },
    onMouseDown(e) {
      // Get the mouse position
      var pos = this.getMousePos(this.canvas, e);

      if (!this.gameover) {
        // Start dragging
        if (!this.drag && this.gamestate == this.gamestates.ready) {
          // Get the tile under the mouse
          var mt = this.getMouseTile(pos);

          if (mt.valid) {
            // Valid tile
            var swapped = false;
            if (this.level.selectedtile.selected) {
              if (
                mt.x == this.level.selectedtile.column &&
                mt.y == this.level.selectedtile.row
              ) {
                // Same tile selected, deselect
                this.level.selectedtile.selected = false;
                this.drag = true;
                return;
              } else if (
                this.canSwap(
                  mt.x,
                  mt.y,
                  this.level.selectedtile.column,
                  this.level.selectedtile.row
                )
              ) {
                // Tiles can be swapped, swap the tiles
                this.mouseSwap(
                  mt.x,
                  mt.y,
                  this.level.selectedtile.column,
                  this.level.selectedtile.row
                );
                swapped = true;
              }
            }

            if (!swapped) {
              // Set the new selected tile
              this.level.selectedtile.column = mt.x;
              this.level.selectedtile.row = mt.y;
              this.level.selectedtile.selected = true;
            }
          } else {
            // Invalid tile
            this.level.selectedtile.selected = false;
          }

          // Start dragging
          this.drag = true;
        }
      }
    },
    onMouseUp() {
      // Reset dragging
      this.drag = false;
    },
    onMouseOut() {
      // Reset dragging
      this.drag = false;
    },
    getMousePos(canvas, e) {
      var rect = canvas.getBoundingClientRect();
      return {
        x: Math.round(
          ((e.clientX - rect.left) / (rect.right - rect.left)) * canvas.width
        ),
        y: Math.round(
          ((e.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height
        ),
      };
    },
    animateLine(coord, i) {
      var intvLightning;
      var startX, startY, endX, endY, step;
      var posX1, posY1, posX2, posY2, posX3, posY3;
      if (this.clusters[i].horizontal) {
        startX = coord.tilex + this.level.tilewidth / 2;
        startY = coord.tiley + this.level.tileheight / 2 - 2;
        endX = startX + (this.clusters[i].length - 1) * this.level.tilewidth;
        endY = startY;
        step = (endX - startX) / 10;
      } else {
        startX = coord.tilex + this.level.tilewidth / 2 - 2;
        startY = coord.tiley + this.level.tileheight / 2;
        endX = startX;
        endY = startY + (this.clusters[i].length - 1) * this.level.tileheight;
        step = (endY - startY) / 10;
      }
      posX1 = posX2 = posX3 = startX;
      posY1 = posY2 = posY3 = startY;

      this.context.beginPath();
      this.context.lineWidth = 3;
      this.context.fillStyle = "#dde5ed";
      this.context.arc(startX, startY, 5, 0, 2 * Math.PI);
      this.context.fill();

      intvLightning = setInterval(() => {
        // Draw lightning 1
        this.context.beginPath();
        this.context.moveTo(posX1, posY1);

        if (this.clusters[i].horizontal) {
          if (posX1 < endX - step) {
            posX1 += step;
            posY1 =
              startY +
              Math.floor(Math.random() * this.level.tilewidth * 0.7) -
              (this.level.tilewidth * 0.7) / 2;
          } else {
            posX1 = endX;
            posY1 = endY;
          }
        } else {
          if (posY1 < endY - step) {
            posY1 += step;
            posX1 =
              startX +
              Math.floor(Math.random() * this.level.tilewidth * 0.7) -
              (this.level.tilewidth * 0.7) / 2;
          } else {
            posX1 = endX;
            posY1 = endY;
          }
        }

        this.context.lineTo(posX1, posY1);
        this.context.shadowBlur = 20;
        this.context.shadowColor = "#f0f1f2";
        this.context.strokeStyle = "#dde5ed";
        this.context.lineWidth = 2;
        this.context.stroke();

        // Draw lightning 2
        this.context.beginPath();
        this.context.moveTo(posX2, posY2);
        if (this.clusters[i].horizontal) {
          if (posX2 < endX - step) {
            posX2 += step;
            posY2 =
              startY +
              Math.floor(Math.random() * this.level.tilewidth * 0.7) -
              (this.level.tilewidth * 0.7) / 2;
          } else {
            posX2 = endX;
            posY2 = endY;
          }
        } else {
          if (posY2 < endY - step) {
            posY2 += step;
            posX2 =
              startX +
              Math.floor(Math.random() * this.level.tilewidth * 0.7) -
              (this.level.tilewidth * 0.7) / 2;
          } else {
            posX2 = endX;
            posY2 = endY;
          }
        }
        this.context.lineTo(posX2, posY2);
        this.context.shadowBlur = 10;
        this.context.shadowColor = "#f5f7fa";
        this.context.strokeStyle = "#f0f1f2";
        this.context.lineWidth = 1;
        this.context.stroke();

        // Draw lightning 3
        this.context.beginPath();
        this.context.moveTo(posX3, posY3);
        if (this.clusters[i].horizontal) {
          if (posX3 < endX - step) {
            posX3 += step;
            posY3 =
              startY +
              Math.floor(Math.random() * this.level.tilewidth * 0.7) -
              (this.level.tilewidth * 0.7) / 2;
          } else {
            posX3 = endX;
            posY3 = endY;
          }
        } else {
          if (posY3 < endY - step) {
            posY3 += step;
            posX3 =
              startX +
              Math.floor(Math.random() * this.level.tilewidth * 0.7) -
              (this.level.tilewidth * 0.7) / 2;
          } else {
            posX3 = endX;
            posY3 = endY;
          }
        }
        this.context.lineTo(posX3, posY3);
        this.context.shadowBlur = 5;
        this.context.shadowColor = "#ffffff";
        this.context.strokeStyle = "#f5f7fa";
        this.context.lineWidth = 1;
        this.context.stroke();

        // Reset positions if all lightning reached the end
        if (this.clusters[i].horizontal) {
          if (posX1 >= endX && posX2 >= endX && posX3 >= endX) {
            this.context.beginPath();
            this.context.lineWidth = 5;
            this.context.fillStyle = "#dde5ed";
            this.context.arc(endX, endY, 5, 0, 2 * Math.PI);
            this.context.fill();
            setTimeout(() => {
              clearInterval(intvLightning);
            }, 200);
          }
        } else {
          if (posY1 >= endY && posY2 >= endY && posY3 >= endY) {
            this.context.beginPath();
            this.context.lineWidth = 5;
            this.context.fillStyle = "#dde5ed";
            this.context.arc(endX, endY, 5, 0, 2 * Math.PI);
            this.context.fill();
            setTimeout(() => {
              clearInterval(intvLightning);
            }, 200);
          }
        }
      }, 50);
      setTimeout(() => {
        clearInterval(intvLightning);
        this.context.shadowBlur = 0;
        this.context.shadowColor = "#ffffff";
        this.isRunning = true;
      }, 700);
    },
    animateCombo() {
      var opacity = 10;
      var intvStyle;
      intvStyle = setInterval(() => {
        this.comboStyle =
          "background-color:rgba(0,0,0," + opacity / 15 + "); z-index:10;";
        opacity--;
      }, 50);
      setTimeout(() => {
        clearInterval(intvStyle);
        this.comboStyle = "background-color:rgba(0,0,0,0); z-index:-1;";
        this.scoreComboText = "";
        this.isRunning = true;
      }, 500);
    },
    async simpanGames() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/simpangames",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.$store.getters.getUserData.username,
              token: this.$store.getters.getUserData.token,
              games: "SPEED",
              skor: this.score,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            console.log("Berhasil menyimpan skor data");
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    async getDataLeaderboard() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getleaderboard",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.$store.getters.getUserData.username,
              token: this.$store.getters.getUserData.token,
              games: "SPEED",
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            this.leaderboardData = data.data;
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    formatNumber(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    },
    async showLeaderboard() {
      await this.getDataLeaderboard();
      this.isLeaderboard = true;
    },
    hideLeaderboard() {
      this.isLeaderboard = false;
    },
  },
  mounted() {
    const speedcanvas = document.getElementById("speedcanvas");
    this.canvas = speedcanvas;
    this.context = speedcanvas.getContext("2d");
    const vw = window.innerWidth;
    this.coinSound.src = require("../assets/audio/strom2.mp3");
    this.coinSound.volume = 0.6;
    this.bgmSound.src = require("../assets/audio/bgmspeed.mp3");
    this.bgmSound.volume = 0.4;
    this.bgmSound.loop = true;
    try {
      this.bgmSound.play().catch(() => {});
    } catch (err) {
      console.log("error play bgm");
    }
    let tileSquare;
    if (vw >= 1920) {
      tileSquare = 80;
    } else if (vw >= 1440 && vw < 1919) {
      tileSquare = 65;
    } else if (vw >= 1280 && vw < 1439) {
      tileSquare = 60;
    } else if (vw >= 768 && vw < 1279) {
      tileSquare = 40;
    } else {
      tileSquare = 30;
    }
    this.level.tilewidth = this.level.tileheight = tileSquare;
    speedcanvas.width = tileSquare * 12;
    speedcanvas.height = tileSquare * 5;
    this.init();
  },
  beforeUnmount() {
    clearInterval(this.counterInterval);
    this.counterInterval == null;
    try {
      this.bgmSound.pause().catch(() => {});
    } catch (err) {
      console.log("error stop bgm");
    }
    clearInterval(this.frameID);
    this.frameID = null;
  },
};
</script>