import { createRouter, createWebHistory } from "vue-router";
import store from "./store";
import SplashScreen from "./components/Splash.vue";
import MainScreen from "./components/Main.vue";
//import RunnerGames from "./components/RunnerGames.vue";
//import SpeedGamesVue from "./components/SpeedGames.vue";
//import HunterGamesVue from "./components/HunterGames.vue";
/*
const isAuthenticated = () => {
  // Implementasi logika autentikasi, misalnya dengan menggunakan Vuex store
  return store.getters.isAuthenticated;
};
*/

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: SplashScreen },
    { path: "/dashboard", component: MainScreen, meta: { requiresAuth: true } },
    //{ path: "/speedgames", component: SpeedGamesVue},
    //{ path: "/huntergames", component: HunterGamesVue},
    //{ path: "/runnergames", component: RunnerGames},
    { path: "/:catchAll(.*)", component: SplashScreen },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check apakah sudah login
    if (store.state.isAuthenticated) {
      const username = store.state.userData.username;
      const token = store.state.userData.token;
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/ceksession",
          {
            method: "POST",
            body: JSON.stringify({
              username: username,
              token: token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            // jika response dari server is authenticated, update local store
            //store.commit("setAuthenticated", true);
            //store.dispatch("resetSessionTimeout");
            next();
          } else {
            console.log(data.message);
            store.dispatch("logout");
            next("/");
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Error checking session:", error);
        next("/");
      }
      // Reset session timeout on user activity
      //store.dispatch("resetSessionTimeout");
      next();
    } else {
      // Redirect to login page
      next("/");
    }
  } else {
    next();
  }
});

export default router;
